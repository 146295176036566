
import { memo, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Favorite, FavoriteBorder, VisibilityOutlined } from "@material-ui/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Navigation, Pagination, Autoplay } from 'swiper';

import { useClickLogs, useSubscribedTitleList } from '../../../Recoil/Title/Titles';
import { useSubscribedAuthorList } from '../../../Recoil/User/Users';
import { readProfile } from '../../../Data/Profile';
import { readPortfolio } from '../../../Data/Portfolio';
import { logBehavior } from '../../../Data/Behavior';

import { fn } from "../../../Functions";

import ParentDisabled from './ParentDisabled';

import './Styles/CarouselCard.scss';
import 'swiper/swiper.min.css';
import 'swiper/scss/pagination';
import { useSession } from "../../../Recoil/Session/Session";
import { useToastAlert } from '@webtoontoday/toast';
import VersatileBadge from "./VersatileBadge";

const lengthCalculator = ( array: {[key: string]: string | number}[] ): number | undefined => {
    if ( !Array.isArray(array) ) return undefined;
    return array.length;
};

const CarouselCard = ({ 
    data, type, finishCallback, play
}:{ 
    data: FeedTitleInfoType, type: 'single' | 'doubleSet',
    finishCallback: () => void, play: boolean
}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const { subscribe: subscribeTitle, unsubscribe: unsubscribeTitle } = useSubscribedTitleList();
    const { subscribe: subscribeAuthor, unsubscribe: unsubscribeAuthor }= useSubscribedAuthorList();

    const { handleClickLogs } = useClickLogs();
    
    const {
        favorite, authorInfo, slides, serviceId, titleId, 
        thumbnail, tags, episodeCount, viewCount, favoriteCount, 
        description, title,
        schoolCompetitionSubmittedYear, schoolCompetitionWinner
    } = data;

    const slideLength = lengthCalculator(slides) || 0;
    const doubleSetSlideLength = lengthCalculator(authorInfo?.otherTitle) || 0;

    // single && doubleSet State
    const [ swiperInstance, setSwiperInstance ] = useState<SwiperClass | null>(null);
    const [ slideIndex, setSlideIndex ] = useState<[number, number]>([0, slideLength - 1] );
    const [ userReaction , setUserReaction ] = useState<{favorite: boolean, subscribe: boolean}>({favorite: !!favorite, subscribe: !!authorInfo.subscribe});
    
    const [ isButtonLoading, setIsButtonLoading ] = useState<boolean>(false);

    // doubleSet State
    const [ otherTitleSwiperInstance, setOtherTitleSwiperInstance ] = useState<SwiperClass | null>(null);
    const [ otherSlideIndex, setOtherSlideIndex ] = useState<number>(0)

    // 자동 재생 이미지 시간 간격
    const slideDelay = 1300;

    useEffect(()=>{
        if ( !play  || !swiperInstance || !swiperInstance.autoplay ) {
            return;
        }
        
        if ( !swiperInstance.isEnd) {
            swiperInstance.autoplay.start();
        } else {
            if (slideLength > 1) {
                swiperInstance.slideTo(0);
            }
            setTimeout( () => finishCallback(), slideDelay );
        }
    },[finishCallback, play, slideLength, swiperInstance, swiperInstance?.autoplay]);
    
    // subscribe favorite Function
    const updateFavoriteHandler = ()=>{
        setIsButtonLoading(true);
        (async () => {
            if (session.memberState !== 'regular') {
                fn.goto('/signin')
            }

            if (userReaction.favorite) {
                const res = await unsubscribeTitle(serviceId, titleId);
                if (!res){
                    toastAlert("관심작품 취소에 실패했습니다.");
                }else{
                    setUserReaction({...userReaction, favorite: false});
                };
            } else {
                const res = await subscribeTitle(serviceId, titleId);
                if (!res){
                    toastAlert("관심작품 등록에 실패했습니다.");
                }else{
                    setUserReaction({...userReaction, favorite: true});
                };
            };
        })()
        setIsButtonLoading(false);
    };

    // subscribe author Function
    const subscribeAuthorHandler = () => {
        setIsButtonLoading(true);
        ( async () => {
            if (session.memberState !== 'regular') {
                fn.goto('/signin')
            }
            
            if (userReaction.subscribe) {
                const res = await unsubscribeAuthor(authorInfo.id);
                if (res) {
                    setUserReaction({...userReaction, subscribe: false});
                } else {
                    toastAlert('관심 작가 취소에 실패했습니다.')
                };
            } else {
                const res = await subscribeAuthor(authorInfo.id);
                
                if (res) {
                    setUserReaction({...userReaction, subscribe: true});
                } else {
                    toastAlert('관심 작가 등록에 실패했습니다.')
                };
            };
        })();
        setIsButtonLoading(false);
    };

    // check and go to portfolio
    const handlePortfolioNavigation = () => {
        setIsButtonLoading(true);
        (async () => {
            swiperInstance?.autoplay.stop();
            const authorProfile = await readProfile(authorInfo?.id);
            const res = await readPortfolio(authorProfile?.addressid);
            logBehavior('click-author', {...authorProfile});
            if ( res ) {
                fn.goto(`/portfolio/${authorProfile.addressid}`);
            } else {
                toastAlert(`아직 작가님의 프로필이 준비되지 않았어요.`);
            };
        })();
        setIsButtonLoading(false);
    };

    const clickCallback = (
        serviceId: string, titleId: string, href?: string
    ) => {
        const timestamp = Math.floor(new Date().getTime()/1000);
        handleClickLogs([{serviceId, titleId, timestamp}]);
        logBehavior("feed-click",{
            clickLogs:[
                {serviceId, titleId, timestamp}
            ],
        })
        fn.goto(href || `/contents/${serviceId}/${titleId}`);
    }
    
    return(<div className={`CarouselCardArea ${play? 'Autoplaying' : ''}`} >
        <div className={'Carousel'}>
            <Swiper
                key={`${serviceId}:${titleId}`} 
                modules={[ Navigation, Pagination, Autoplay ]} slidesPerView={1} spaceBetween={0}
                autoplay={play?{
                    delay: slideDelay,
                    stopOnLastSlide: true,
                    disableOnInteraction: false,
                }:false}
                pagination={{
                    el: `.my-custom-feed-pagination-div-${serviceId}-${titleId}`,
                    clickable: true,
                    dynamicBullets: true,
                    renderBullet: (index, className) => {
                        return `<span class=${className}> </span>`
                    }
                }}
                onActiveIndexChange={(swiper) => {
                    if (play) {
                        if (swiper.isEnd) {
                            setTimeout(() => finishCallback(), slideDelay);
                        };
                    } else {
                        swiper.autoplay.stop();
                    };
                    setSlideIndex([swiper.activeIndex, slideLength - 1])
                }}
                onSwiper={ ( swiper ) => setSwiperInstance(swiper) }
                style={{ borderRadius: `${type === 'single'?'5px':'5px 0 0 5px'}` }}
            >
                {data && data.slides.length < 1 
                    ?<SwiperSlide key={`Slide:${serviceId}:${titleId}`}>
                        <img loading={'eager'} src={thumbnail || 'https://static.webtoon.today/noimage.jpg'} alt={'swiperSlide'} style={{width: '100%'}} onClick={async() => clickCallback(serviceId, titleId)} />
                    </SwiperSlide>
                    :slides
                        .map((image, index) => (
                            <SwiperSlide key={`${image}:${index}`} style={{position: 'relative'}} >
                                <img 
                                    loading={ (index === 0) ? 'eager' : 'lazy' }
                                    src={image.image} alt={'swiperSlide'} style={{width: '100%'}} 
                                    onClick={async() => clickCallback(serviceId, titleId, image?.href) }
                                />
                            </SwiperSlide>
                ))}
                {/* TODO 특정 키 제거 */}
                {type === 'single' && !( serviceId === "today" && titleId === "univ2023" )
                    ?<div className={'WebtoonInfoHover'}>
                        <div className={'AuthorName'}>
                            {authorInfo.name}
                        </div>
                        <div className={'TitleRow'}>
                            <div className={'Title'} onClick={async() => clickCallback(serviceId, titleId)}>
                                {title}
                            </div>
                            <div className={'TitleFavorite'} >
                                <Favorite style={{display: 'flex', color: `${userReaction.favorite?'rgba(235, 84, 77, 1)':'rgba(225, 226, 227, 1)'}`}} onClick={updateFavoriteHandler} />
                                <ParentDisabled show={isButtonLoading} />
                            </div>
                        </div>
                        <div className={'WebtoonInfo'}>
                            <div className={'EpisodeInfo'}>
                                {`총 ${episodeCount}회 ${(tags.filter( tag => tag ) || []).length > 0? `ㅣ ${tags.filter( tag => tag ).slice(0,2).join('·')}` : ''}`}
                            </div>
                            <div className={'CountInfo'} >
                                <FavoriteBorder className={'FavoriteCountIcon'} />
                                {favoriteCount + (userReaction.favorite?1:0)}
                                <VisibilityOutlined className={'ViewCountIcon'} />
                                {viewCount}
                            </div>
                        </div>
                    </div>
                    :<></>}
            </Swiper>
            <div className={`ArrowBox Prev ${slideLength < 2 || slideIndex[0] === 0 ? 'Hidden':''}`} >
                <ChevronLeft fontSize={'small'} className={'Arrow'} onClick={ () => swiperInstance?.slidePrev() } />
            </div>
            <div className={`ArrowBox Next ${slideLength < 2 || slideIndex[0] === slideIndex[1] ? 'Hidden' : ''}`} onClick={ () => swiperInstance?.slideNext() } >
                <ChevronRight fontSize={'small'} className={'Arrow'} />
            </div>
            <div className={'Pagination'}>
                <div className={'HoverBackground'} />
                <div className={`my-custom-feed-pagination-div-${serviceId}-${titleId}`} />
            </div>
            {   schoolCompetitionSubmittedYear 
            && <VersatileBadge year={schoolCompetitionSubmittedYear} winner={schoolCompetitionWinner} />}
        </div>
        {type==='doubleSet'
        ?<div className={'CarouselSet'}>
            <div className={'TitleBox'}>
                <div onClick={async() => clickCallback(serviceId, titleId)}>{title}</div>
                <div className={'FavoriteIconBox'}>
                    <Favorite style={{display: 'flex', color: `${userReaction.favorite?'rgba(235, 84, 77, 1)':'rgba(225, 226, 227, 1)'}`, cursor: 'pointer'}} onClick={updateFavoriteHandler} />
                    <ParentDisabled show={isButtonLoading} />
                </div>
            </div>
            <div className={'WebtoonInfoBox'}>
                <div className={'EpisodeInfo'}>
                    {`총 ${episodeCount}회 ${(tags.filter( tag => tag ) || []).length > 0? `ㅣ ${tags.filter( tag => tag ).slice(0,2).join('·')}` : ''}`}
                </div>
                <div className={'CountInfo'} >
                    <FavoriteBorder className={'FavoriteCountIcon'}  />
                    {favoriteCount + (userReaction.favorite?1:0)}
                    <VisibilityOutlined className={'ViewCountIcon'} />
                    {viewCount}
                </div>
            </div>
            <div className={'DescriptionBox'}>
                {description}
            </div>
            <div className={'CarouselSetDevideLine'} />
            <div className={'AuthorBox'}>
                <div className={'ProfileInfo'}>
                    <div className={'ProfileImage'} >
                        <img src={authorInfo?.image || "https://static.webtoon.today/noimage.jpg"} alt={'AuthorImage'} style={{width: 36, height: 36, borderRadius: '50%', marginRight: 9, cursor: 'pointer'}} onClick={handlePortfolioNavigation} />
                        <ParentDisabled show={isButtonLoading} />
                    </div>
                    <div className={'TextInfo'}>
                        <div className={'NameText'} onClick={handlePortfolioNavigation} >
                            {authorInfo?.name}
                        </div>
                        <div className={'SubscriptionCount'}>
                            {`${authorInfo?.subscriptionCount + (userReaction.subscribe?1:0)} 팔로잉`}
                        </div>
                    </div>
                </div>
                <div className={'FollowBox'}>
                    {userReaction.subscribe
                        ?<div className={'Following'} onClick={subscribeAuthorHandler} >{'팔로잉'}</div>
                        :<div className={'Follow'} onClick={subscribeAuthorHandler} >{'팔로우'}</div>}
                    <ParentDisabled show={isButtonLoading} />
                </div>
            </div>
            {(authorInfo?.otherTitle || []).length > 0
            ?<>
                <div className={`OtherTitleLabel`}>
                    {'작가님의 작품'}
                </div>
                <div className={`OtherTitleSlider`} >
                    <Swiper
                        key={`OtherTitleSlider:${serviceId}:${titleId}`}
                        onSwiper={(swiper)=> setOtherTitleSwiperInstance(swiper)} 
                        modules={[ Navigation ]}
                        slidesPerView={4} spaceBetween={10}
                        onActiveIndexChange={(swiper) => {
                            setOtherSlideIndex(swiper.activeIndex)
                        }}
                        style={{
                            width: '100%', position: 'relative'
                        }}
                    >
                        {authorInfo?.otherTitle.map( title => (
                            <SwiperSlide key={`otherTitle:${title.serviceId}:${title.titleId}`} style={{position: 'relative'}} >
                                <img 
                                    src={title.thumbnail} alt={title.titleId} 
                                    style={{width: 68.67, height: 68.67, borderRadius: 16, border: '1px solid rgb(225, 226, 228)', cursor: 'pointer', objectFit: 'cover'}} key={`OtherTitle:${title.serviceId}:${title.titleId}`} 
                                    onClick={()=>fn.goto(`/contents/${title.serviceId}/${title.titleId}`)}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={`OtherTitleSetArrow Prev ${doubleSetSlideLength < 5 || otherSlideIndex === 0 ? 'Hidden':''}`} >
                        <ChevronLeft className={'Arrow'} onClick={ () => otherTitleSwiperInstance?.slidePrev() } />
                    </div>
                    <div className={`OtherTitleSetArrow Next ${doubleSetSlideLength < 5 || otherSlideIndex === doubleSetSlideLength - 1 ? 'Hidden' : ''}`} onClick={ () => otherTitleSwiperInstance?.slideNext() } >
                        <ChevronRight className={'Arrow'} />
                    </div>
                </div>
            </>:<></>}
        </div>:<></>}
    </div>)
}

export default memo(CarouselCard);