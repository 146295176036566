import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { GuideTextBoxForGeneral } from '../../../../../Component/TextBox';

import './Univ2023ApplicationForm.scss';
import WithLabel from '../../../../../Component/WithLabel';
import { GuideTextBoxForStandAloneVerification } from '../../../../../Component/TextBox';
import { GuideTextBoxForPairedVerification } from '../../../../../Component/TextBox';
import SingleUploadImageDisplayed from '../../../Components/SingleUploadImageDisplayed';
import MultiUploadFilenameDisplayed from '../../../Components/MultiUploadFilenameDisplayed';
import MultiUploadImageDisplayed from '../../../Components/MultiUploadImageDisplayed';
import { readProfile } from '../../../../../Data/Profile';
import { verifyEmail, verifyOtp } from '../../../../../Data/Email';
import { readLoginInfo } from '../../../../../Data/Account';
import { useSession, useSignIn } from '../../../../../Recoil/Session/Session';
import { getTitle } from '../../../../../Data/Title';
import UnivDisclaimer from './UnivDisclaimer';
import { randomString, sortFunc } from '../../../../../Functions';
import { Button, Dialog } from '@material-ui/core';
import AdsSlides from '../../../../../Component/AdsSlides';
import TextArea from '../../../../Enroll/Component/TextArea';
import AutocompletableTextField from '../../../../../Component/AutoCompletableTextField';
import TextCheckBox from '../../../../../Component/TextCheckBox';
import { logBehavior } from '../../../../../Data/Behavior';


const maxLength = 25;

const stepperContent = [
    ['AnalysisApplicationForm0','기본 정보'], 
    ['AnalysisApplicationForm1','작품 정보'], 
    ['AnalysisApplicationForm2','회차 원고'], 
    ['AnalysisApplicationForm3','광고 이미지']
];
const stepperHeight = 126;
const recognitionArea = stepperHeight + 25;
const recognitionAreaForLast = 165;
const phoneRegExp = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;

const ApplicationForm = () => {
    const { session } = useSession();
    const { EmailTokenSignin } = useSignIn();

    const [ hasTriedApplying, setHasTriedApplying ] = useState<boolean>(false);
    const [ isSpeechBalloonVisible, setIsSpeechBalloonVisible  ] = useState<boolean>(true);

    const [didClickSchool, setDidClickSchool] = useState(false);

    const [ agreements, setAgreements ] = useState<agreementType>({
        serviceProvisionAgreement: false,
        marketingAgreement: false,
        webtoonCopyingAgreement: false,
        analysisCopyingAgreement: false,
        platformUploadAgreement: false,
    })

    const [ analysisApplicationData, setAnalysisApplicationData ] = useState<analysisUniv2023ApplicationType>({
        // BasicInfo
        serviceType: "2023schoolCompetition",
        nameKor: '',
        addressid: randomString(16),
        addressidValidationStatus: 'undone',
        email: '',
        emailValidationStatus: 'undone',
        emailKey: '',
        emailKeyValidationStatus: 'undone',
        school: '',
        phoneNumber: '',
        //WebtoonInfo
        title: '',
        titleId: randomString(16),
        titleIdValidationStatus: 'undone',
        representativeImage: [],
        description: '',
        //EpisodeInfo
        episodeInfo: [
            {
                manuscripts: [],
                thumbnail: [],
                isAutoThumbnail: false,
            }
        ],
        //adCutInfo
        adCuts: [],
        isAutoAdCut: false,
        //referrer
        referrer: '',
    })
    const [ isMoreEpisode, setIsMoreEpisode ] = useState<boolean>(false);
    const [ openAdCutPreview, setOpenAdCutPreview ] = useState<boolean>(false);

    const {
        nameKor, addressidValidationStatus, emailValidationStatus, emailKeyValidationStatus,
        title, titleIdValidationStatus, representativeImage, description,
        episodeInfo, school, phoneNumber, adCuts, 
    } = analysisApplicationData;

    const basicInfoStatus = 
           nameKor.length > 0
        && school.length > 0
        && phoneNumber.length > 0
        && addressidValidationStatus === 'success' 
        && emailValidationStatus === 'success' 
        && emailKeyValidationStatus === 'success'
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'
    
    const webtoonInfoStatus = 
           title.length > 0
        && titleIdValidationStatus === 'success'
        && representativeImage.length > 0
        && description.length > 0
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'

    const { manuscripts, thumbnail } = episodeInfo[0];
    
    const episodeInfoStatus = 
           manuscripts.length > 0
        && (thumbnail[0]?.name.length > 0  )
        && (thumbnail[0]?.image.length > 0  )
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'
    
    const adCutInfoStatus = 
        (adCuts.length > 0 )
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'

    const isApplicationCompleted = basicInfoStatus === 'success'
                                && webtoonInfoStatus === 'success'
                                && episodeInfoStatus === 'success'
                                && adCutInfoStatus === 'success'
    
    const validationStatuses: ('success' | 'caution' | 'undone')[] = [basicInfoStatus, webtoonInfoStatus, episodeInfoStatus, adCutInfoStatus];
    
    const [currentSections, setCurrentSections] = useState<string[]>([]);
    const [viewHeight, setViewHeight] = useState<number>(window.innerHeight);
    const hasLastSectionPassed = useRef<boolean>(true);
    
    const currentSection = currentSections[currentSections.length - 1];

    const handleClick = useCallback((id: string) => {
        if(id === currentSection) return;
        const element = document.getElementById(id);
        if(!element) return;

        element.scrollIntoView({behavior: 'smooth'});
        const scrollAmount = -(stepperHeight - element.getBoundingClientRect().top);
        window.scrollBy({top: scrollAmount, behavior: 'smooth'});
    }, [currentSection]);

    useEffect(() => {
        // for components except the last one
        const options = {
            rootMargin: `${-stepperHeight}px 0px ${-viewHeight + recognitionArea}px 0px`
        }
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    setCurrentSections(prevSections => [...prevSections, entry.target.id]);
                } else{
                    setCurrentSections(prevSections => prevSections.filter(prevSection => prevSection !== entry.target.id));
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        for(let i = 0; i < stepperContent.length; i++){
            const element = document.getElementById(stepperContent[i][0]);
            if(element){
                observer.observe(element);
            }
        }

        // for the last component
        const optionsForLast = {
            rootMargin: `${-viewHeight + recognitionAreaForLast}px 0px 0px 0px`
        }
        const handleIntersectionForLast = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if(hasLastSectionPassed.current){
                if(entry.isIntersecting){
                    setCurrentSections(prevSections => prevSections.filter((prevSection, index, prevSections) => !(prevSection === entry.target.id && index === prevSections.length - 1)));
                    hasLastSectionPassed.current = false;
                }
            } else{
                if(!entry.isIntersecting && entry.boundingClientRect.top < viewHeight){
                    setCurrentSections(prevSections => [...prevSections, entry.target.id]);
                    hasLastSectionPassed.current = true;
                }
            }
        }

        const observerForLast = new IntersectionObserver(handleIntersectionForLast, optionsForLast);
        const lastElement = document.getElementById(stepperContent[stepperContent.length - 1][0]);
        if(lastElement){
            observerForLast.observe(lastElement);
        }

        return () => {
            for(let i = 0; i < stepperContent.length; i++){
                const element = document.getElementById(stepperContent[i][0]);
                if(element){
                    observer.unobserve(element);
                }
            }
            const lastElement = document.getElementById(stepperContent[stepperContent.length - 1][0]);
            if(lastElement){
                observerForLast.unobserve(lastElement);
            }
        }
    }, [viewHeight])

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;

        const handleResize = () => {
            clearTimeout(timeoutId);
            
            timeoutId = setTimeout(() => {
                setViewHeight(window.innerHeight);
            }, 300);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const { addressid, email, memberState } = session;
        if(memberState !== 'regular'){
            return;
        }
        
        const sessionAddressid = addressid || '';
        const sessionEmail = email || '';
        setAnalysisApplicationData(prev => ({...prev,
            addressid: sessionAddressid,
            addressidValidationStatus: 'success',
            email: sessionEmail,
            emailValidationStatus: 'success',
            emailKeyValidationStatus: 'success'
        }));
    }, [session])
    
    return (
        <div className={'ApplicationForm'} >
            <Stepper
                currentSection={currentSection}
                stepperContent={stepperContent}
                handleClick={handleClick}
                validationStatuses={validationStatuses}
            />
            <div className={'Form'}>
                <div 
                    id={stepperContent[0][0]}
                    className={'BasicInfo'}
                    onClick={() => handleClick(stepperContent[0][0])}
                >
                    <SectionHeader content={`1. ${stepperContent[0][1]}`} />
                    <hr className={'InfoLine'} />
                    <WithLabel label={'이메일'}>
                        <div className={'EmailInputBox'}>
                            <GuideTextBoxForPairedVerification
                                purpose={'send'}
                                text={analysisApplicationData.email}
                                validationPattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})*$/}
                                placeholder={'이메일을 입력해주세요.'}
                                onBlur={() => {
                                    if ( analysisApplicationData.email ) {
                                        logBehavior("input-emailUniv2023");
                                    }
                                }}
                                onChange={(e) => {
                                    const newText = e.target.value.trim();
                                    setAnalysisApplicationData(prev => ({...prev, email: newText, emailKey: '', emailValidationStatus: 'undone', emailKeyValidationStatus: 'undone'}));
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '인증되었어요.',
                                    fail: '',
                                    required: '인증을 완료해주세요.'
                                }}
                                validationStatus={analysisApplicationData.emailValidationStatus}
                                secondStepValidationStatus={analysisApplicationData.emailKeyValidationStatus}
                                onClick={() => {
                                    verifyEmail(analysisApplicationData.email);
                                    setAnalysisApplicationData(prev =>({...prev, emailValidationStatus: 'success'}))
                                }}
                                isDisabled={session.memberState === 'regular'}
                                forcedGuideTextType={hasTriedApplying && analysisApplicationData.emailValidationStatus === 'undone' ? 'required' : undefined}
                            />
                            {( analysisApplicationData.emailValidationStatus === 'success' && analysisApplicationData.emailKeyValidationStatus !== 'success') &&  
                                <GuideTextBoxForStandAloneVerification
                                    purpose={'verification'}
                                    text={analysisApplicationData.emailKey}
                                    placeholder={'인증 키를 입력하세요'}
                                    onChange={(e) => {
                                        const newText = e.target.value.trim();
                                        
                                        setAnalysisApplicationData(prev => ({...prev, emailKey: newText, emailKeyValidationStatus: 'undone'}));
                                    }}                        
                                    guideTexts={{
                                        initial: '',
                                        normal: '',
                                        success: '',
                                        fail: '인증키를 다시 확인해주세요.',
                                        required: '인증을 완료해주세요.'
                                    }}
                                    validationStatus={analysisApplicationData.emailKeyValidationStatus}
                                    onClick={async () => {
                                        setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'pending'}));

                                        const {isProfileExist} = await readLoginInfo(analysisApplicationData.email);

                                        if(isProfileExist){
                                            const response = await EmailTokenSignin(analysisApplicationData.email, analysisApplicationData.emailKey);

                                            if(response){
                                                setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'success', titleIdValidationStatus: 'undone'}));
                                            } else{
                                                setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'fail'}));
                                            }
                                        } else{
                                            const response = await verifyOtp(analysisApplicationData.email, analysisApplicationData.emailKey);
                                            if(response){
                                                setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'success'}));
                                            } else{
                                                setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'fail'}));
                                            }
                                        }
                                    }}
                                    forcedGuideTextType={hasTriedApplying && analysisApplicationData.emailKeyValidationStatus === 'undone' ? 'required' : undefined}
                                />}
                        </div>
                    </WithLabel>
                    <WithLabel label={'이름 / 필명'}>
                        <GuideTextBoxForGeneral
                            text={analysisApplicationData.nameKor}
                            placeholder={'이름을 입력해주세요.'}
                            onChange={(e) => {
                                const value = e.target.value;
                                
                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, nameKor: value.slice(0, maxLength)}));
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, nameKor: value}));
                                }
                            }}
                            onBlur={(e) => {
                                const value = e.target.value.trim();
                                
                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, nameKor: value.slice(0, maxLength)}));
                                    logBehavior("input-nameUniv2023");
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, nameKor: value}));
                                }
                            }}
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: '필수 입력 정보입니다.'
                            }}
                            isRequired={true}
                            maxLength={maxLength}
                            forcedGuideTextType={hasTriedApplying && (analysisApplicationData?.nameKor || "").length === 0 ? 'required' : undefined}
                        />
                    </WithLabel>
                    <WithLabel label={'이름 / 필명 (영문) '} >
                        <GuideTextBoxForStandAloneVerification
                            purpose={'uniqueness'}
                            text={analysisApplicationData.addressid}
                            placeholder={'작가 이름을 입력하세요'}
                            onBlur={()=>{
                                if( analysisApplicationData.addressid) {
                                    logBehavior("input-engNameUniv2023");
                                }
                            }}
                            onChange={(e) => {
                                if (/[^a-zA-Z0-9_]/g.test(e.target.value)){
                                    return;
                                }

                                const newText = e.target.value.trim();
                                setAnalysisApplicationData(prev => ({...prev, addressid: newText}));
                                setAnalysisApplicationData(prevState =>({...prevState, addressidValidationStatus: 'undone'}));
                            }}
                            guideTexts={{
                                initial: '랜덤으로 생성된 이름이에요. 수정 할 수 있어요.',
                                normal: '랜덤으로 생성된 이름이에요. 수정 할 수 있어요.',
                                success: '사용할 수 있는 이름이에요.',
                                fail: '중복되지 않는 이름을 입력해주세요.',
                                required: '중복 확인이 필요해요.'
                            }}
                            validationStatus={analysisApplicationData.addressidValidationStatus}
                            onClick={async () => {
                                setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'pending'}));
                                
                                const res = await readProfile(analysisApplicationData.addressid);
                                if(res.addressid){
                                    setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'fail'}));
                                } else{
                                    setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'success'}));
                                }
                            }}
                            maxLength={maxLength}
                            isDisabled={session.memberState === 'regular'}
                            forcedGuideTextType={hasTriedApplying && analysisApplicationData.addressidValidationStatus === 'undone' ? 'required' : undefined}
                        />
                    </WithLabel>
                    <WithLabel label={'학교명'}>
                        <div className={'SchoolInputBox'}>
                            <AutocompletableTextField
                                placeholder={'학교명 또는 학원명을 입력해주세요.'}
                                candidates={[
                                    {pattern: /[가-힇]+학$/, prefix: '', postfix: '교'},
                                    {pattern: /[가-힇]+학$/, prefix: '', postfix: '원'},
                                    {pattern: /[가-힇]+대$/, prefix: '', postfix: '학교'},
                                    {pattern: /[가-힇]+고$/, prefix: '', postfix: '등학교'},
                                    {pattern: /[가-힇]+[가-닿댁-힇] *$/, prefix: '', postfix: '대학교'},
                                    {pattern: /[가-힇]+[가-곟곡-힇] *$/, prefix: '', postfix: '고등학교'},
                                    {pattern: /[가-힇]+[가-곟곡-힇] *$/, prefix: '', postfix: '아카데미'},
                                ]}
                                antiPattern={/학교|학원|예고|한예종|아카데미|오늘의웹툰/}
                                onBlur={(e)=>{
                                    const value = e.target.value.trim();
                                    
                                    if (maxLength >= 0) {
                                        logBehavior("input-schoolUniv2023");
                                        setAnalysisApplicationData(prev => ({...prev, school: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, school: value}));
                                    }
                                    setDidClickSchool(true)
                                }}
                                fullWidth style={{
                                    paddingLeft:0
                                }}
                                type="text"
                                value={school}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        fontSize: '0.8125rem',
                                        borderRadius: 4,
                                        padding: "2px 0",
                                        height: 48,
                                    },
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, school: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, school: value}));
                                    }
                                }}
                            />
                            <div className={'CheckOutWithGuide'}>
                                {(didClickSchool && !analysisApplicationData.school)
                                    ? <span className={'Required'}>{'필수 입력 정보입니다.'}</span>
                                    :<div></div>}
                                <TextCheckBox
                                    text={"소속이 없습니다 "}
                                    disabled={false}
                                    isChecked={analysisApplicationData.school === '오늘의웹툰'}
                                    onChange={(e)=>{
                                        if (e.target.checked) {
                                            setAnalysisApplicationData(prev => ({...prev, school: '오늘의웹툰'}))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </WithLabel>
                    <WithLabel label={'전화번호'}>
                        <GuideTextBoxForGeneral
                            text={analysisApplicationData.phoneNumber}
                            placeholder={'전화번호를 입력해주세요.(-를 제외하고 입력해주세요)'}
                            onChange={(e) => {
                                const value = e.target.value.trim().replace(/[^0-9]/,"");;
                                
                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, phoneNumber: value.slice(0, maxLength)}));
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, phoneNumber: value}));
                                }
                            }}
                            onBlur={(e) => {
                                let value = e.target.value.trim().replace(/[^0-9]/,"");

                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, phoneNumber: value.slice(0, maxLength)}));
                                    logBehavior("input-phoneNumberUniv2023");
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, phoneNumber: value}));
                                }
                            }}
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: '필수 입력 정보입니다.'
                            }}
                            isRequired={true}
                            forcedGuideTextType={
                                hasTriedApplying && analysisApplicationData.phoneNumber.length === 0 
                                    ? 'required'
                                    : analysisApplicationData.phoneNumber.length === 0 
                                        ? undefined 
                                            : phoneRegExp.test(analysisApplicationData.phoneNumber) 
                                                ? 'success'
                                                : 'fail'
                            }
                        />
                    </WithLabel>
                </div>
                <div 
                    id={stepperContent[1][0]}
                    className={'WebtoonInfo'}
                    onClick={() => handleClick(stepperContent[1][0])}
                >
                    <SectionHeader content={`2. ${stepperContent[1][1]}`} />
                    <WithLabel label={'작품 제목'}>
                        <GuideTextBoxForGeneral
                            text={analysisApplicationData.title}
                            placeholder={'작품의 제목을 입력해주세요.'}
                            onChange={(e) => {
                                const value = e.target.value;

                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, title: value.slice(0, maxLength)}));
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, title: value}));
                                }
                            }}
                            onBlur={(e) => {
                                const value = e.target.value.trim();

                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, title: value.slice(0, maxLength)}));
                                    logBehavior("input-titleUniv2023");
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, title: value}));
                                }
                            }}
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: '필수 입력 정보입니다.'
                            }}
                            isRequired={true}
                            maxLength={maxLength}
                            forcedGuideTextType={hasTriedApplying && analysisApplicationData.title.length === 0 ? 'required' : undefined}
                        />
                    </WithLabel>
                    <WithLabel label={'작품 제목 (영문)'} >
                        <GuideTextBoxForStandAloneVerification
                            purpose={'uniqueness'}
                            text={analysisApplicationData.titleId}
                            placeholder={'작품 제목을 입력하세요'}
                            onBlur={() => {
                                if ( analysisApplicationData.titleId ) {
                                    logBehavior("input-titleIdUniv2023");
                                }
                            }}
                            onChange={(e) => {
                                if (/[^a-zA-Z0-9_]/g.test(e.target.value)){
                                    return;
                                }
                                const newText = e.target.value.trim();
                                
                                if (maxLength >= 0) {
                                    setAnalysisApplicationData(prev => ({...prev, titleId: newText.slice(0, maxLength), titleIdValidationStatus: 'undone'}));
                                } else {
                                    setAnalysisApplicationData(prev => ({...prev, titleId: newText, titleIdValidationStatus: 'undone'}));
                                }
                            }}
                            guideTexts={{
                                initial: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                normal: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                success: '사용할 수 있는 제목이에요.',
                                fail: '중복되지 않는 제목을 입력해주세요.',
                                required: '중복 확인이 필요해요.'
                            }}
                            validationStatus={analysisApplicationData.titleIdValidationStatus}
                            onClick={async () => {
                                setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'pending'}));

                                const searchedTitles = await getTitle(analysisApplicationData.addressid, analysisApplicationData.titleId);
                                if(searchedTitles.length > 0){
                                    setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'fail'}));
                                } else{
                                    setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'success'}));
                                }
                            }}
                            maxLength={maxLength}
                            forcedGuideTextType={hasTriedApplying && analysisApplicationData.titleIdValidationStatus === 'undone' ? 'required' : undefined}
                        />
                    </WithLabel>
                    <WithLabel label={'줄거리'}>
                        <TextArea
                            text={analysisApplicationData.description}
                            onChange={(value) => setAnalysisApplicationData( prevData => ({ ...prevData, description: value }))}
                            rows={5}
                            maxLength={400}
                            fullWidth
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: '필수 입력 정보입니다.'
                            }}
                            isRequired={true}
                            forcedGuideTextType={hasTriedApplying && analysisApplicationData.description.length === 0 ? 'required' : undefined}
                            onBlur={()=>{
                                if ( analysisApplicationData.description ) {
                                    logBehavior("input-descriptionUniv2023");
                                }
                            }}
                        />
                    </WithLabel>
                    <WithLabel label={'대표 이미지'} >
                        <SingleUploadImageDisplayed 
                            width={110} height={110}
                            files={analysisApplicationData.representativeImage}
                            setFiles={(files) => setAnalysisApplicationData( prev => ({...prev, representativeImage: files}))}
                            screenSubtitle={["600px * 600px"]}
                        />
                    </WithLabel>
                </div>
                <div 
                    id={stepperContent[2][0]}
                    className={'ManuscriptInfoContainer'}
                    onClick={() => handleClick(stepperContent[2][0])} 
                >
                    {episodeInfo.map( (episode, idx) => (
                        <div className={'ManuscriptInfo'} key={idx}>
                            {idx === 0 && <SectionHeader content={`3. ${stepperContent[2][1]}`} />}
                            <WithLabel label={`${idx + 1}화 원고`} >
                                <MultiUploadFilenameDisplayed
                                    screenTitle={"마우스로 파일을 끌어서 넣을 수 있어요."}
                                    screenSubtitle={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "가로 690px 이상"]}
                                    files={analysisApplicationData.episodeInfo[idx].manuscripts}
                                    setFiles={(files) => setAnalysisApplicationData( prev => {
                                        let newEpisodeInfo = [...prev.episodeInfo];
                                        newEpisodeInfo[idx].manuscripts = files;
                                        const isAllLoaded = !newEpisodeInfo[idx].manuscripts.find( imageObj => !imageObj.image.includes("https://static.webtoon.today") );

                                        if ( isAllLoaded && files.length > 0 ) {
                                            newEpisodeInfo[idx].manuscripts = files.sort( (a,b) => sortFunc(a.image, b.image) );
                                        }

                                        return {...prev, episodeInfo: newEpisodeInfo};
                                    })}
                                    isItemDraggable={false}
                                />
                            </WithLabel>
                            <WithLabel label={`${idx + 1}화 썸네일`} >
                                <SingleUploadImageDisplayed 
                                    width={217} height={110}
                                    screenSubtitle={["이미지 파일(JPG 나 PNG)", "202px * 102px"]}
                                    files={analysisApplicationData.episodeInfo[idx].thumbnail}
                                    setFiles={(files) => setAnalysisApplicationData( prev => {
                                        const newEpisodeInfo = [...prev.episodeInfo];
                                        newEpisodeInfo[idx].thumbnail = files;
                                        return {...prev, episodeInfo: newEpisodeInfo};
                                    })}
                                />
                            </WithLabel>
                        </div>
                    ))}
                </ div>
                <div className={`AdditionalEpisodeButton ${isMoreEpisode?'Cancel':''}`} 
                    onClick = {() => setAnalysisApplicationData(prev => {
                        setIsSpeechBalloonVisible (false);
                        if ( !isMoreEpisode ) {
                            setIsMoreEpisode(true);
                            return ({ ...prev, episodeInfo: [
                                ...prev.episodeInfo, 
                                {   
                                    manuscripts: [],
                                    thumbnail: [],
                                    isAutoThumbnail: false,
                                }, {   
                                    manuscripts: [],
                                    thumbnail: [],
                                    isAutoThumbnail: false,
                                },
                            ] });
                        } else {
                            setIsMoreEpisode(false);
                            return ({ ...prev, episodeInfo: [ prev.episodeInfo[0] ] });
                        }
                    })}
                >
                    { !isMoreEpisode && <img src={'https://static.webtoon.today/ddah/icon/icon_add.svg'} alt={'icon_add'} height={'100%'} /> }
                    <div>
                        {!isMoreEpisode
                            ?<div className={'EpisodeMoreText'} >{'추가 회차분 업로드하기'}</div>
                            :<div className={'CancelEpisodeMoreText'} >{'추가 업로드 취소'}</div>
                        }
                        {episodeInfoStatus === 'success' && isSpeechBalloonVisible &&
                        <div className={'SpeechBalloon'} >
                            <img src={'https://static.webtoon.today/ddah/speech_balloon.png'} alt={'speech_balloon'} width={'100%'} />
                            <div className={'BalloonContent'} >{'회차를 등록하고 더 유용한 분석 결과를 받으세요!'}</div>
                        </div>}
                    </div>
                </div>
                <div 
                    id={stepperContent[3][0]}
                    className={'AdCutInfo'}
                    onClick={() => handleClick(stepperContent[3][0])} 
                >
                    <SectionHeader content={`4. ${stepperContent[3][1]}`} />
                    <WithLabel label={'광고 이미지'} >
                        <MultiUploadImageDisplayed 
                            gridItemCounts={5}
                            screenTitle={"마우스로 파일을 끌어서 넣을 수 있어요."}
                            screenSubtitle={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "600px * 600px"]}
                            files={analysisApplicationData.adCuts}
                            setFiles={ (files: imageType[]) => setAnalysisApplicationData(prev => ({...prev, adCuts: files}) )}
                        />
                        <div />
                        <Button  className={`AdsPreviewPaper`} disabled={adCuts.length < 1} onClick={()=>setOpenAdCutPreview(true)} >
                            {'홍보컷 미리보기'}
                        </Button>
                    </WithLabel>
                    <Dialog open={openAdCutPreview} disableScrollLock  onClose={()=>setOpenAdCutPreview(false)} >
                        <AdsSlides title={{title, slides: adCuts}} />
                    </Dialog>
                </div>
                <div className={'AdCutInfo'} >
                    <WithLabel label={'추천인(선택)'}>
                        <GuideTextBoxForGeneral
                            text={analysisApplicationData.referrer}
                            placeholder={'추천인의 이메일을 적어주세요.'}
                            onChange={(e) => {
                                const value = e.target.value;

                                setAnalysisApplicationData(prev => ({...prev, referrer: value}));
                            }}
                            onBlur={(e) => {
                                const value = e.target.value.trim();

                                setAnalysisApplicationData(prev => ({...prev, referrer: value}));
                                logBehavior("input-referrerUniv2023");
                            }}
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '올바르지 않은 이메일입니다.',
                                required: ''
                            }}
                            isRequired={true}
                            forcedGuideTextType={
                                analysisApplicationData.referrer.length > 0 
                                    ? !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})*$/.test(analysisApplicationData.referrer)
                                        ? 'fail'
                                        : undefined
                                    : undefined
                            }
                        />
                    </WithLabel>
                </div>
                <hr className={'HorizontalLine'} />
            </div>
            <UnivDisclaimer 
                agreements={agreements} isApplicationCompleted={isApplicationCompleted}
                setAgreements={(agreements) => setAgreements(agreements)}
                analysisApplicationData={analysisApplicationData}
                triedApplying={() => {
                    if (hasTriedApplying) return;
                    setHasTriedApplying(true);
                }}
            />
        </div>
    );
};

const Stepper = ({currentSection, stepperContent, handleClick, validationStatuses}: {
    currentSection: string,
    stepperContent: string[][],
    handleClick: Function,
    validationStatuses: ('success' | 'caution' | 'undone')[]
}) => {
    return (
        <div className={'Stepper'}>
            {stepperContent.map(([id, category], index) => (
                <Step
                    key={id}
                    isCurrentSection={currentSection === stepperContent[index][0]}
                    ordinalNumber={index + 1}
                    content={category}
                    validationStatus={validationStatuses[index]}
                    onClick={() => handleClick(id)}
                />
            ))}
        </div>
    );
}

const stepStatusToIcon = {
    'undone': 'data:image/svg+xml;charset=utf-8,<svg width="1" height="1" viewBox="0 0 1 1" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>',
    'caution': 'https://static.webtoon.today/ddah/icon/icon_caution.svg',
    'success': 'https://static.webtoon.today/ddah/icon/icon_success.svg'
}
const Step = ({isCurrentSection, ordinalNumber, content, validationStatus, onClick}: {
    isCurrentSection: boolean,
    ordinalNumber: number,
    content: string,
    validationStatus: 'undone' | 'caution' | 'success',
    onClick: MouseEventHandler<HTMLDivElement>
}) => {
    return (
        <div
            className={`Step${isCurrentSection ? ' Current': ''}`}
            onClick={onClick}
        >
            <span>{ordinalNumber}</span>
            <div className={'Content'}>
                <span>
                    {content}
                </span>
                <img
                    src={stepStatusToIcon[validationStatus]}
                    alt={validationStatus}
                    width={24}
                    height={24}
                />
            </div>
        </div>
    )
}

const SectionHeader = ({content} : {content: string}) => {
    return (
        <div className={'SectionHeader'}>
            <span>{content}</span>
            <hr className={'InfoLine'} />
        </div>
    )
}

export default ApplicationForm;