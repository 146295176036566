import React, { useEffect, useLayoutEffect, useState } from 'react';

import './Landing.scss';
import '../../../../Component/WTInteractionStyledDialog.scss';
import '../../../../Component/WTConfirmationStyledDialog.scss';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../../../Recoil/Session/Session';
import { GuideTextBoxForGeneral } from '../../../../Component/TextBox';
import { createCompetitionEmail } from '../../../../Data/Competition';
import { logBehavior } from '../../../../Data/Behavior';
import { useToastAlert } from '@webtoontoday/toast';
import LoadingCircle from '../../../../Component/LoadingCircleAnimated';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { isCeremoneyEnd, isBeforeCompetition, isOnSubmission, isUnderReview } from '../Data/Variables';
import { fn } from '../../../../Functions';
import PrizeSlider from './Components/PrizeSlider';
import { announcementType, readSchoolCompetition } from '../../../../Data/SchoolCompetition';

const Landing = ({query}: {query: string}) => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const [ isApplicationDialogOpen, setIsApplicationDialogOpen ] = useState<boolean>(false);
    const [ isCompletionDialogOpen, setIsCompletionDialogOpen ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    useLayoutEffect(() => {
        setPageHeader({
            isAppbarHidden: true,
            isHiddenForPC: true,
            isHiddenForMobile: true
        })
    }, [setPageHeader])

    useEffect(() => {
        const searchParams = new URLSearchParams(query);
        const newEmail = searchParams.get('email');
        if(newEmail){
            setEmail(newEmail);
            return;
        }

        if(session.email){
            setEmail(session.email);
        }
    }, [query, session.email])

    let promotionPhrase1, promotionPhrase2;
    if(isBeforeCompetition){
        promotionPhrase1 = '과제 부활전🔥 소식받고';
        promotionPhrase2 = '총 상금 600만원을 노려보세요!';
    } else if(isOnSubmission){
        promotionPhrase1 = '과제 부활전🔥 에 참여하고';
        promotionPhrase2 = '총 상금 600만원을 노려보세요!';
    } else{
        promotionPhrase1 = '2023 과제 부활전에';
        promotionPhrase2 = '많은 관심 주셔서 감사합니다.';
    }

    let buttons;
    if(isBeforeCompetition){
        buttons = (
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(61, 106, 255, 1)',
                    color: 'rgba(255, 255, 255, 1)'
                }}
                onClick={() => {
                    logBehavior('click-openPromotion')
                    setIsApplicationDialogOpen(true)
                }}
            >
                {'오픈 알림 받기'}
            </Button>
        );
    } else if(isOnSubmission){
        buttons = (<>
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(61, 106, 255, 1)',
                    color: 'rgba(255, 255, 255, 1)',
                    fontFamily: 'inherit'
                }}
                onClick={(e) => {
                    logBehavior("click-applyUniv2023");
                    fn.gotoByAnchor(e);
                }}
                href='/analysis/univ2023/application'
            >
                <span>
                    {'과제 부활전 참여'}
                </span>
            </Button>
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(224, 226, 235, 1)',
                    color: 'rgba(60, 70, 81, 1)',
                    fontFamily: 'inherit'
                }}
                onClick={(e) => {
                    logBehavior("click-applicationDetailsUniv2023");
                    fn.gotoByAnchor(e)
                }}
                href='/analysis/univ2023/mypage'
            >
                {'내 작품 확인'}
            </Button>
        </>);
    } else if(isUnderReview){
        buttons = (<>
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(61, 106, 255, 1)',
                    color: 'rgba(255, 255, 255, 1)',
                    fontFamily: 'inherit'
                }}
                onClick={fn.gotoByAnchor}
                href='/'
            >
                <span>
                    {'참여 작품 보러가기'}
                </span>
            </Button>
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(224, 226, 235, 1)',
                    color: 'rgba(60, 70, 81, 1)',
                    fontFamily: 'inherit'
                }}
                onClick={fn.gotoByAnchor}
                href='/analysis/univ2023/mypage'
            >
                {'내 작품 확인'}
            </Button>
        </>);
    } else{
        buttons = (
            <Button
                className="PromotionButton"
                style={{
                    backgroundColor: 'rgba(61, 106, 255, 1)',
                    color: 'rgba(255, 255, 255, 1)',
                    fontFamily: 'inherit'
                }}
                onClick={fn.gotoByAnchor}
                href='/'
            >
                <span>
                    {'홈에서 당선작 확인'}
                </span>
            </Button>
        );
    }

    let landingImageUrls: (string)[] = [];

    if ( isCeremoneyEnd ) { 
        //TODO 당선작 이미지들 추가
        landingImageUrls = Array(12)
        .fill(0).map((v,i) => i+1)
        .map( order => `https://static.webtoon.today/ddah/2023_university/image/winner_result/winner_result_${("00" + order).slice(-3)}.png`);
    } else {
        //당선작 이전에 가이드라인 이미지들
        landingImageUrls = Array(5)
                            .fill(0).map((v,i) => i+1)
                            .map( order => `https://static.webtoon.today/ddah/2023_university/image/guideline/guideline_0${order}.jpg`);
    }
    
    return (
        <div className="Univ2023LandingPage">
            <div className="Univ2023LandingContent">
                <div className="ContestContent">
                    <div className="PosterArea" style={{display: 'flex', flexDirection: 'column'}} >
                        <img src="https://static.webtoon.today/ddah/2023_university/image/2023poster.jpg" alt="과제부활전 내용"/>
                        {isCeremoneyEnd
                            ? <PrizeAnnouncement />
                            : landingImageUrls.map(url => 
                                <img src={url} alt={url} key={url} />
                            )
                        }
                        <div className="Univ2022Result" >
                            <Button className="Univ2022Winners" href="/univ2022" 
                            onClick={(event) => {
                                logBehavior("click-winnerUniv2022");
                                fn.gotoByAnchor(event);
                            }} >{'2022년 당선작 확인'}</Button>
                        </div>
                        <AdditionalNotice device={"mobile"} />
                    </div>
                    <div className="ButtonArea">
                        <Button
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(51, 51, 53, 1)',
                                padding: '10px 16px',
                                borderRadius: '8px',
                                fontFamily: 'inherit'
                            }}
                            onClick={(e) => {
                                logBehavior("click-notionGuideUniv2023");
                                fn.gotoByAnchor(e);
                            }}
                            href="https://www.notion.so/webtoon-today/2023-d8cb36b978574b49964f320a36617c82?pvs=4"
                        >
                            <span>{'과제 부활전 운영 정책'}</span>
                            <img src="https://static.webtoon.today/ddah/icon/chevron_right_bold.svg" alt="이동" width={'24px'} height={'24px'} />
                        </Button>
                    </div>
                </div>
                <div className="ContestPromotion">
                    <div className="ContestPromotionPhrase">
                        <span>{promotionPhrase1}</span>
                        <span>{promotionPhrase2}</span>
                    </div>
                    {buttons}
                    <AdditionalNotice device={"pc"} />
                </div>
            </div>
            {isBeforeCompetition && <>
            <Dialog
                open={isApplicationDialogOpen}
                onClose={() => setIsApplicationDialogOpen(false)}
                className="WTInteractionStyledDialog"
            >
                <DialogTitle style={{padding: '0px 0px 16px 0px'}}>
                    <div className="Title" style={{textAlign: 'left', wordBreak: 'keep-all'}}>
                        {'오픈 알림 받고\n총 상금 600만원에 도전해보세요.'}
                    </div>
                    <div className="SubTitle" style={{textAlign: 'left'}}>
                        {'공모전이 오픈하는 23년 12월 18일에\n아래의 메일을 통해 알려드릴게요.'}
                    </div>
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 4}} >
                        <div style={{fontSize: '0.9375rem', fontWeight: 500, lineHeight: '24px', letterSpacing: "0.15px"}}>{'이메일'}</div>
                        <GuideTextBoxForGeneral
                            text={email}
                            placeholder={'오픈 알림을 받을 이메일을 입력해주세요'}
                            onChange={ (e) => setEmail(e.target.value) }
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: ''
                            }}
                            isRequired={true}
                        />
                    </div>
                </DialogContent>
                <DialogActions style={{padding: 0}}>
                    <Button
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(61, 106, 255, 1)',
                            color: 'rgba(255, 255, 255, 1)',
                            fontFamily: 'inherit'
                        }}
                        onClick={async () => {
                            setIsLoading(true);

                            const response = await createCompetitionEmail({email, type: '2023university'})
                            await logBehavior('submit-competitionEmail(ready)', response);
                            
                            if (response && response.type && response.email) {
                                await logBehavior('submit-competitionEmail(success)', response);
                                setIsApplicationDialogOpen(false);
                                setIsCompletionDialogOpen(true);
                            } else {
                                toastAlert({
                                    message: '다시 한 번 시도해주세요.',
                                    timeout: 3000,
                                    iconType: 'warning'
                                })
                            }
                            setIsLoading(false);
                        }}
                        disabled={isLoading}
                    >
                        {'오픈 알림 받기'}
                    </Button>
                </DialogActions>
                <DialogContent className="Footer-root" style={{padding: 0}}>
                    {'이메일은 오픈 알림 및 공지를 위해서만 사용됩니다.'}
                </DialogContent>
            </Dialog>
            <Dialog
                open={isCompletionDialogOpen}
                onClose={() => setIsCompletionDialogOpen(false)}
                className="WTConfirmationStyledDialog"
            >
                <DialogTitle>
                    <div className="Title">
                        <img src='https://static.webtoon.today/ddah/icon/icon_success.svg' alt='성공' width={'28px'} height={'28px'} />
                        {'\n오픈 알림을 신청했어요.'}
                    </div>
                    <div className="SubTitle">
                        {'과제 부활전이 시작할 때\n제출하신 이메일로 알려드릴게요!'}
                    </div>
                </DialogTitle>
                <DialogActions>
                    <Button
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(71, 71, 71, 1)',
                            color: 'rgba(255, 255, 255, 1)'
                        }}
                        onClick={() => setIsCompletionDialogOpen(false)}
                    >
                        {'확인'}
                    </Button>
                </DialogActions>
            </Dialog>
            </>}
            <LoadingCircle show={isLoading} />
        </div>
    );
};

const AdditionalNotice = ({device}:{device: "pc" | "mobile"}) => {

    return(<div className={`NoticeBox ${device}`} >
        <div className={'NoticeArticle'}>
            <div className={'NoticeTitle'}>
                {'[일정 관련 공지]'}
            </div>
            <div className={'NoticeDescription'}>
                {`29일 오후 2시 이후로는 심의 결과 중간 안내 및 재접수 요청이
                이루어지지 않습니다. 해당 시점에 제출한 작품으로 최종 심의가
                이루어지기 때문에 재접수 시 반려되지 않도록 원고와 광고이미지에
                각별한 주의를 기울여 주시기 바랍니다.`}
            </div>
        </div>
        <div className={'NoticeArticle'}>
            <div className={'NoticeTitle'}>
                {'[광고 이미지]'}
            </div>
            <div className={'NoticeContent'}>
                <ul>
                    <li>{"광고 이미지을 본 독자들이 해당 작품을 보고 싶어지게 광고 이미지를 만들어주세요."}</li>
                    <li>{"작품 평가에 핵심적인 역할을 합니다."}</li>
                    <li><a className={'HyperLink'} href={"https://challenge.webtoon.today/report/today/the-law-of-the-jungle/1/94"} onClick={fn.gotoByAnchor}>{"SNS에 노출되는 형태 미리보기 (클릭!)"}</a></li>
                    <li>{"한 장짜리 광고 이미지, 내용을 이해할 수 없는 광고 이미지로는 접수가 불가능합니다."}</li>
                </ul>
            </div>
        </div>
        <div className={'NoticeArticle'}>
            <div className={'NoticeTitle'}>
                {'[광고 이미지]'}
            </div>
            <div className={'NoticeContent'}>
                <ul>
                    <li>{"작품의 핵심 장면을 담아보세요."}</li>
                    <li>{"대사나 자막을 추가해서 장면을 설명해도 좋아요."}</li>
                    <li>{"자막을 통한 설명이 어렵다면 대사가 있는 컷으로 스토리 전개를 설명 해주셔도 좋습니다."}</li>
                    <li>{"장면 나열보다는 스토리가 연결되도록 구성해 주세요."}</li>
                    <li>{"대사가 잘리지 않게 광고 이미지를 구성해 주세요."}</li>
                </ul>
            </div>
            <div className={'ExampleImages'} >
                <img src={"https://static.webtoon.today/ddah/2023_university/image/advertising_example.png"} alt={'examples'} style={{width: '100%', maxWidth: 360}} />
            </div>
        </div>
    </div>)
}


const PrizeAnnouncement = () => {

    const [ data, setData ] = useState<announcementType>({
        prizeAnnouncementTitle: "",
        winnerPrizes: [],
        schoolPrizes: [],
        standardAndStatistics: [],
        readerStatistics: [],
        interactionStatistics: [],
        ceremonyStatistics: "",
    })

    useLayoutEffect(() => {
        (async()=>{
            const res = await readSchoolCompetition();
            if ( res ) {
                setData(res);
            }
        })()
    },[]);
    
    let isValid = true;
    for ( const datum of Object.values(data) ) {
        if ( !datum ) {
            isValid = false;
            break;
        } else {
            if ( datum.length === 0 ) {
                isValid = false;
                break;
            }
        }
    }

  if ( !isValid ) {
        return <></>;
    }
    
    const { 
        prizeAnnouncementTitle, winnerPrizes, schoolPrizes, 
        standardAndStatistics, readerStatistics, 
        interactionStatistics, ceremonyStatistics
    } = data;

    return(<div className={'PrizeAnnouncement'} >
        {/* 과제부활전 당선작 발표 제목 */}
        <img className={'PrizeAnnouncementTitle'} src={prizeAnnouncementTitle} alt={'prizeTitle'} />
        {/* 수상작 */}
        {winnerPrizes.map( ({image, url}) => <div className={'PrizeBackground'} key={image} >
            <img src={image} alt={'SinglePrize'} />
            <div className={'ButtonBackground'} >
                <Button className={'VisitButton Wide'} href={url} onClick={fn.gotoByAnchor} > {"작품 보러 가기"} </Button>
            </div>
        </div>) }
        <div className={'Divider Prize'} ><hr className={'DidiveStoke'} /></div>
        {/* 개별 학교 수상작 */}
        {schoolPrizes.map( ({image, urls}) => <div className={'PrizeBackground'} key={image} >
            <img src={image} alt={'SinglePrize'} />
            <div className={'ButtonBackground'} >
                <Button className={'VisitButton'} href={urls[0]} onClick={fn.gotoByAnchor} > {"작품 보러 가기"} </Button>
                <Button className={'VisitButton'} href={urls[1]} onClick={fn.gotoByAnchor} > {"작품 보러 가기"} </Button>
            </div>
        </div>) }
        <div className={'Divider Standard'} ><hr className={'DidiveStoke'} /></div>
        {/* 선정 기준과 통계 결과 1*/}
        {standardAndStatistics.map( (image) => <img src={image} alt={'standards'} key={image} />)}
        {/* 슬라이드 1, 독자에 관한 통계 */}
        <div className={'SliderBackground'}>
            <PrizeSlider 
                slides={readerStatistics} 
            />
        </div>
        {/* 슬라이드 2, 작품에 관한 통계 */}
        {<div className={'SliderBackground'}>
            <PrizeSlider 
                slides={interactionStatistics}
            />
        </div>}
        <div className={'Divider Statistics'} ><hr className={'DidiveStoke'} /></div>
        {/* 통계 결과2 */}
        {<img src={ceremonyStatistics} alt={'ceremonyStatistics'} key={'ceremonyStatistics'} />}
    </div>)
}

export default Landing;