import React from 'react';
import { analyticsSubmitInfo } from "../../../Data/AnalyticsSubmit";

import './Styles/UnivAlarmBar.scss';
import ChannelService from "../../../Component/ChannelService";
import { currentSchoolCompetitionYear, isOnSubmission, isUnderReview } from "../../Analysis/Univ2023/Data/Variables";

const UnivAlarmBar = ({submitInfos}:{submitInfos: analyticsSubmitInfo}) => {

    const isThisYearSchoolCompetition = submitInfos.schoolCompetitionSubmittedYear === currentSchoolCompetitionYear;

    const isSubmittedWork = submitInfos.isSchoolCompetitionSubmitted || submitInfos.isSchoolCompetitionModifiable;
    const isShowAlarmBar  = isSubmittedWork && (isOnSubmission || isUnderReview) && isThisYearSchoolCompetition;

    if ( !isShowAlarmBar ) {
        return <></>;
    } else {
        return(<div className={'UnivAlarmBarArea'}>
            <div className={'NoticeBox'}>
                <img src={"https://static.webtoon.today/ddah/icon/info_circle_dark.svg"} alt={'info_circle_dark'} style={{marginRight: 8}} />
                <span>{`문의사항이 있으시면 아래의 `}</span>
                <span className={'ImpressedText'} onClick={()=>ChannelService.ChannelIO()('openChat')} >{`채널톡`}</span>
                <span>{"을 통해 알려주세요.️ ☺️"}</span>
            </div>
            {submitInfos.isSchoolCompetitionModifiable && isOnSubmission && <div className={'ReApplyBox'} >
                <img src={"https://static.webtoon.today/ddah/icon/icon_warning.svg"} alt={'icon_warning'} style={{marginRight: 8}} />
                <span>{`재접수 요청을 받은 작품이네요. 작품 접수는 `}</span>
                <span className={'ImpressedText'}>{`12/31일 `}</span>
                <span>{"까지 가능하니 수정 후 "}</span>
                <span className={'ImpressedText'}>{`재접수`}</span>
                <span>{"해주세요."}</span>
            </div>}
        </div>)
    }

}

export default UnivAlarmBar;