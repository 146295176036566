import React, { useState, ReactNode, useEffect, CSSProperties } from 'react';
import { useSetRecoilState } from 'recoil';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Share as ShareIcon } from '@material-ui/icons';

import { useSession } from '../../Recoil/Session/Session';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

import { createTitle, deleteTitle, getTitle, updateTitle } from '../../Data/Title';
import { listMyOwnTitles } from '../../Data/Ownership';
import { logBehavior } from '../../Data/Behavior';

import { GuideTextBoxForGeneral, GuideTextBoxForStandAloneVerification } from '../../Component/TextBox';
import RadioGroup from '../Analysis/Components/RadioGroup';
import MultiUploadImageDisplayed from '../Analysis/Components/MultiUploadImageDisplayed';
import TextCheckBox from '../../Component/TextCheckBox';
import SingleUploadImageDisplayed from '../Analysis/Components/SingleUploadImageDisplayed';
import Hashtag from './Component/Hashtag';
import TextArea from './Component/TextArea';
import Dropdown from './Component/Dropdown';
import WithLabel from '../../Component/WithLabel';
import LoadingCircle from '../../Component/LoadingCircle';
import Share from '../../Component/Share';

import { flags, fn, randomString } from '../../Functions';

import './Styles/PostTitle.scss';
import { analyticsSubmitInfo, readAnalyticsSubmitInfo } from '../../Data/AnalyticsSubmit';
import { isLockedMonth, isOnSubmission, isUnderReview, currentSchoolCompetitionYear } from '../Analysis/Univ2023/Data/Variables';
import { useTitles } from '../../Recoil/Title/Titles';
import UnivAlarmBar from './Component/UnivAlarmBar';
import { readUnivReport } from '../../Data/Report';
import { useToastAlert } from '@webtoontoday/toast';

const maxLength = 25;

const exposureOptions: {value: ( "public" | "unlist" | "private" ), label: ReactNode}[] = [
    { value: "public", label: "공개하기" },
    { value: "unlist", label: "홈에서는 비공개" },
    { value: "private", label: "일단은 나만 보기" },
]
const storyOptions: {value: ("story" | "episode"), label: ReactNode}[] = [
    { value: "story", label: "장편 스토리" },
    { value: "episode", label: "단편 에피소드" },
]

const sectionContent = [
    ['AnalysisApplicationForm0','기본 정보'], 
    ['AnalysisApplicationForm1','홍보컷 & 대표 이미지'], 
    ['AnalysisApplicationForm2','추가 정보']
];
const isSectionNecessary: {[key: string]: boolean} = {
    "기본 정보": true,
    "홍보컷 & 대표 이미지": false, 
    "추가 정보": false,
} 
const sectionSubtitles: {[key: string]: string} = {
    "기본 정보": "독자들이 작품을 만날 때 가장 먼저 전달되는 정보에요.",
    "홍보컷 & 대표 이미지": "매력적인 이미지를 등록하고 더 많은 독자들을 사로잡아 보세요.", 
    "추가 정보": "상세한 정보를 전달해서 더욱 많은 독자들이 작품을 감상하도록 할 수 있어요.",
}

const initialPostTitle: titleRegistrationInfoType = {
    // Basic Info
    title: '', titleId: randomString(16),
    titleIdValidationStatus: 'undone',
    exposure: 'public',
    // adCutsAndThumbnail Info
    adCuts: [],
    isAutoAdCut: false,
    thumbnail: [],
    isAutoThumbnail: false,
    // additional Info
    storyType: undefined,
    isAdult: false,
    genre: [],
    description: '',
    tags: ["",""],
}

type dialogStatusType = 'backwardWarning' | 'deleteOpen' | 'postNewOpen' | 'deleteInformOpen'

let oldExposure = 'public';
let openBackWarning = false;
let isTitleModified = false;

const PostTitle = ({ serviceId: propServiceId, titleId: propTitleId, edit }:{ serviceId: string, titleId: string, edit: boolean}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const { titleObject, fetch: fetchTitles, refreshTitle } = useTitles();
    const isLogin = session.memberState === 'regular';

    if ( !isLogin ) {
        if ( propServiceId && propTitleId ) {
            fn.goto(`/signin?redirectTo=${encodeURIComponent(`/enroll/${propServiceId}/${propTitleId}`)}`);
        } else {
            fn.goto(`/signin`);
        }
    }

    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    const [ dialogStatus, setDialogStatus ] = useState<{[key in dialogStatusType]: boolean}>({
        backwardWarning:false, deleteOpen:false, postNewOpen:false,
        deleteInformOpen:false,
    })

    const [ thisTitleServiceId, setThisTitleServiceId ] = useState<string>('');
    const [ hasTriedApplying, setHasTriedApplying ] = useState<boolean>(false);

    const [ isEditing, setIsEditing ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ currentTitle, setCurrentTitle ] = useState<titleRegistrationInfoType>(initialPostTitle)
    const [ titleRegistrationInfo, setTitleRegistrationInfo ] = useState<titleRegistrationInfoType>(initialPostTitle)

    const [ school, setSchool ] = useState<string>("");
    const [ phoneNumber, setPhoneNumber ] = useState<string>("");
    
    const { 
        title, titleId, titleIdValidationStatus, exposure, adCuts, 
        isAutoAdCut, thumbnail, storyType, isAdult,
        genre, tags, description
    } = titleRegistrationInfo;
    
    const basicInfoStatus = 
           title.length > 0
        && titleIdValidationStatus === 'success'
        && ['public', 'unlist', 'private'].includes(exposure)
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'

    useEffect(()=>{
        if ( !isLogin ) return;
        const previousPage = localStorage.getItem('previousurl');
        if (edit) {
            if ( isTitleModified ) {
                if (previousPage === `/enroll/${propServiceId}/${propTitleId}`) {
                    openBackWarning = true;
                    fn.goto(`/enroll/${propServiceId}/${propTitleId}`);
                };
            } else {
                if (previousPage === `/contents/${propServiceId}/${propTitleId}`) {
                    fn.goto(`/enroll/${propServiceId}/${propTitleId}`);
                } else {
                    fn.goto(`/contents/${propServiceId}/${propTitleId}`)
                };
            };
        } else {
            if ( openBackWarning ) {
                setDialogStatus( prev => ({ ...prev, backwardWarning: true }) );
                openBackWarning = false;
            };
        };
    },[edit, isLogin, propServiceId, propTitleId])

    useEffect( () => {
        const [ serviceId, titleId ] = [ propServiceId, propTitleId ];
        fetchTitles([{serviceId, titleId}]);

        (async()=>{
            if ( !(serviceId && titleId) ) return;
            const submitInfo = await readAnalyticsSubmitInfo({serviceId, titleId});
            setSubmitInfos(submitInfo);
        })();
        (async()=>{
            const univReports = await readUnivReport({serviceId, titleId});

            if ( univReports ) {
                const latestReport = univReports[0];
                setSchool(latestReport?.schoolInfo ||"");
                setPhoneNumber(latestReport?.phoneNumber ||"");
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[propServiceId, propTitleId])

    // 헤더 설정
    useEffect(()=>{
        if ( !isLogin ) return;
        
        setPageHeader({
            titleText: "작품 등록", isTitleHidden: true, isStickyTop: true,
            isBackable: true, headerStyle: {color: 'rgb(255,255,255)'},
            mobileShortcuts: <>
                <IconButton >
                    <Share title={title} contents={<ShareIcon />} log={{ what: 'share-title', detail: {serviceId: propServiceId, titleId: propTitleId} }} mobileStyle={{color: 'rgb(255,255,255)'}} />
                </IconButton>
            </>})
    },[setPageHeader, propServiceId, propTitleId, title, isLogin])

    // 작품 수정일 경우 작품 정보 불러오기
    useEffect(()=>{
        ( async()=>{
            if ( !propServiceId || !propTitleId || !isLogin ) return;
            setIsLoading(true)
            const ownershipList = await listMyOwnTitles();

            if ( propTitleId ) {
                const currentOwnership = ownershipList.find( ownership => ownership.titleId === propTitleId && ownership.serviceId === propServiceId);
                if ( !currentOwnership ) {
                    fn.goto(`/contents/${propServiceId}/${propTitleId}`);
                    return
                };

                const { serviceId, titleId } = currentOwnership;

                const currentTitle = titleObject[`${serviceId}:${titleId}`];

                if (currentTitle) {
                    setIsEditing(true);
                    setThisTitleServiceId(currentTitle.serviceId);
                    const {
                        title, titleId, exposure, slides, thumbnail,
                        storyType, isAdult, tags, keywords, description
                    } = currentTitle;

                    oldExposure = exposure;
                    const selectedTags: string[] = keywords.split(',').concat(["",""]).slice(0,2);
                    const unSelectedTags: string[] = keywords.split(',').slice(2);
                    
                    const newTags = selectedTags.concat(selectedTags.filter( keyword => keyword ).concat(unSelectedTags))
                    
                    const genre = tags.length > 0 ? tags.split(',') : []
                    const newThumbnail = thumbnail.length > 0 
                    ? [{
                        name: thumbnail.split('/').reverse()[0].split('.')[0],
                        image: thumbnail,
                        createdAt: new Date().getTime() / 1000
                    }]:[];
                    
                    const loadedTitle: titleRegistrationInfoType = {
                        // Basic Info
                        title, titleId,
                        titleIdValidationStatus: 'success',
                        exposure,
                        // AdCutsAndThumbnail Info
                        adCuts: slides,
                        isAutoAdCut: false,
                        thumbnail: newThumbnail,
                        isAutoThumbnail: false,
                        // Additional info
                        storyType,
                        isAdult,
                        genre,
                        description,
                        tags: newTags,
                    }

                    setCurrentTitle(loadedTitle);
                    setTitleRegistrationInfo(loadedTitle);
                }
            }
            setIsLoading(false);
        })()
    },[isLogin, propServiceId, propTitleId, session.addressid, titleObject])
    

    // 타이틀 수정 여부 확인
    useEffect( () => {
        if (isLoading || edit) return;

        const selectedTags = tags.slice(0,2);
        const filteredSelectedTags = selectedTags.filter( tag => tag );
        const unSelectedTags = tags.slice(2).filter(tag => !selectedTags.includes(tag));
        
        const newKeywords = [...selectedTags, ...filteredSelectedTags, ...unSelectedTags];

        const modifyingTitle = {
            ...currentTitle,
            titleId, title, genre:genre, description, thumbnail,
            tags: newKeywords,
            adCuts, storyType, exposure, isAdult: isAdult
        };

        if ( JSON.stringify(currentTitle) === JSON.stringify(modifyingTitle) ) {
            isTitleModified = false;
        } else {
            isTitleModified = true;
        }
        
    },[adCuts, currentTitle, description, edit, exposure, genre, isAdult, isLoading, storyType, tags, thisTitleServiceId, thumbnail, title, titleId])
    
    const [ submitInfos, setSubmitInfos ] = useState<analyticsSubmitInfo>({ isAnalyticsSubmitted: false,
                                                                            isCompetitionSubmitted: false,
                                                                            isSchoolCompetitionSubmitted: false,
                                                                            isSchoolCompetitionModifiable: false,
                                                                            isAnalyticsValidatedAt: false,
                                                                            isAnalyticsCompleted: false,
                                                                            isAutomatedAnalyticsCompleted: false,
                                                                            analyticsSubmitCreatedAt: '',
                                                                            key: '',
                                                                            schoolCompetitionSubmittedYear:''});

    // 데이터 분석 신청 진행중일 땐 수정 금지
    const { isAnalyticsSubmitted, isAnalyticsCompleted } = submitInfos;

    // 공모전 진행중 ~ 공모전 발표후 한 달까지 삭제/수정 금지 , 반려인 경우는 수정 가능

    const isSchoolAccepted = submitInfos.isSchoolCompetitionSubmitted
    const isSchoolRejected = submitInfos.isSchoolCompetitionModifiable
    const isSchoolCompetition = isSchoolAccepted || isSchoolRejected;

    const isThisYearSchoolCompetition = submitInfos.schoolCompetitionSubmittedYear === currentSchoolCompetitionYear;

    let isDeleteVisible: boolean = true;
    let isEditVisible: boolean   = true;
    let editLabel                = "수정";


    if ( isAnalyticsSubmitted && !isAnalyticsCompleted ) {
        isDeleteVisible = false;
        isEditVisible   = false;
        editLabel       = "수정불가";
    } else if (isSchoolCompetition && isThisYearSchoolCompetition) {

        if        (isOnSubmission && isSchoolRejected) {
            isDeleteVisible = false;
            isEditVisible   = true;
            editLabel       = "재접수";
        } else if (isOnSubmission && isSchoolAccepted) {
            isDeleteVisible = false;
            isEditVisible   = false;
            editLabel       = "수정불가";
        } else if (isUnderReview  || isLockedMonth) {
            isDeleteVisible = false;
            isEditVisible   = false;
            editLabel       = "수정불가";
        } else {
            isDeleteVisible = true;
            isEditVisible   = true;
            editLabel       = "수정";
        }

    } else {
        isDeleteVisible = true;
        isEditVisible   = true;
        editLabel       = "수정";
    }

    return(
        <div className={'PostTitleArea'} >
            <div className={`PostTitleInner${!isEditVisible?" Disabled":""}`}>
                <div className={'Form'} >
                    {isSchoolCompetition && isThisYearSchoolCompetition && <UnivAlarmBar submitInfos={submitInfos} />}
                    <div
                        id={sectionContent[0][0]}
                        className={'BasicInfo'}
                    >
                        <SectionHeader content={`1. ${sectionContent[0][1]}`} subTitle={sectionSubtitles[sectionContent[0][1]]} isNecessary={isSectionNecessary[sectionContent[0][1]]} />
                        <hr className={'InfoLine'} />
                        <WithLabel label={'작품 제목'}>
                            <GuideTextBoxForGeneral
                                text={title}
                                placeholder={'작품의 제목을 입력해주세요.'}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    if (maxLength >= 0) {
                                        setTitleRegistrationInfo(prev => ({...prev, title: value.slice(0, maxLength)}));
                                    } else {
                                        setTitleRegistrationInfo(prev => ({...prev, title: value}));
                                    }
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value.trim();

                                    if (maxLength >= 0) {
                                        setTitleRegistrationInfo(prev => ({...prev, title: value.slice(0, maxLength)}));
                                    } else {
                                        setTitleRegistrationInfo(prev => ({...prev, title: value}));
                                    }
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                maxLength={maxLength}
                                forcedGuideTextType={hasTriedApplying && title.length === 0 ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'작품 제목 (영문)'} >
                            <GuideTextBoxForStandAloneVerification
                                purpose={'uniqueness'}
                                text={titleId}
                                placeholder={'작품 제목을 입력하세요'}
                                onChange={(e) => {
                                    if (/[^a-zA-Z0-9_]/g.test(e.target.value)){
                                        return;
                                    }
                                    const newText = e.target.value.trim();
                                    
                                    if (maxLength >= 0) {
                                        setTitleRegistrationInfo(prev => ({...prev, titleId: newText.slice(0, maxLength), titleIdValidationStatus: 'undone'}));
                                    } else {
                                        setTitleRegistrationInfo(prev => ({...prev, titleId: newText, titleIdValidationStatus: 'undone'}));
                                    }
                                }}
                                guideTexts={{
                                    initial: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                    normal: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                    success: '사용할 수 있는 제목이에요.',
                                    fail: '중복되지 않는 제목을 입력해주세요.',
                                    required: '중복 확인이 필요해요.'
                                }}
                                validationStatus={titleIdValidationStatus}
                                onClick={async () => {
                                    setTitleRegistrationInfo(prevData =>({...prevData, titleIdValidationStatus: 'pending'}));

                                    const searchedTitles = await getTitle(session.addressid, titleId);
                                    if(searchedTitles.length > 0){
                                        setTitleRegistrationInfo(prevData =>({...prevData, titleIdValidationStatus: 'fail'}));
                                    } else{
                                        setTitleRegistrationInfo(prevData =>({...prevData, titleIdValidationStatus: 'success'}));
                                    }
                                }}
                                maxLength={maxLength}
                                isDisabled={isEditing}
                                forcedGuideTextType={hasTriedApplying && titleIdValidationStatus === 'undone' ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'공개 범위 설정'}>                            
                            <RadioGroup<"unlist" | "private" | "public">
                                value={exposure} fullWidth
                                options={exposureOptions}
                                onChange={(value) => setTitleRegistrationInfo(prev => ({...prev, exposure: value}))}
                            />
                        </WithLabel>
                        {submitInfos.isSchoolCompetitionModifiable && isOnSubmission && isThisYearSchoolCompetition &&
                        <><WithLabel label={'학교명'}>
                            <GuideTextBoxForGeneral
                                text={school}
                                placeholder={'학교명 또는 학원명을 입력해주세요.'}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    
                                    setSchool(value);
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value.trim();
                                    
                                    setSchool(value);
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                forcedGuideTextType={hasTriedApplying && (school ||"").length === 0 ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'전화번호'}>
                            <GuideTextBoxForGeneral
                                text={phoneNumber}
                                placeholder={'전화번호를 입력해주세요.(-를 제외하고 입력해주세요)'}
                                onChange={(e) => {
                                    const value = e.target.value.trim().replace(/[^0-9]/,"");;
                                    
                                    setPhoneNumber(value);
                                }}
                                onBlur={(e) => {
                                    let value = e.target.value.trim().replace(/[^0-9]/,"");

                                    setPhoneNumber(value);
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                forcedGuideTextType={
                                    hasTriedApplying && phoneNumber.length === 0 
                                        ? 'required'
                                        : phoneNumber.length === 0 
                                            ? undefined 
                                                : /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(phoneNumber) 
                                                    ? 'success'
                                                    : 'fail'
                                }
                            />
                        </WithLabel></>}
                    </div>
                    <div
                        id={sectionContent[1][0]}
                        className={'AdCutsAndThumbnailInfo'}
                    >
                        <SectionHeader content={`2. ${sectionContent[1][1]}`} subTitle={sectionSubtitles[sectionContent[1][1]]} isNecessary={isSectionNecessary[sectionContent[1][1]]} />
                        <hr className={'InfoLine'} />
                        <WithLabel label={'광고 이미지'} >
                            <MultiUploadImageDisplayed
                                gridItemCounts={5}
                                screenTitle={"마우스로 파일을 끌어서 넣을 수 있어요."}
                                screenSubtitle={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "600px * 600px"]}
                                files={adCuts}
                                setFiles={ (files: imageType[]) => setTitleRegistrationInfo(prev => ({...prev, adCuts: files}) )}
                            >
                                {/* 공모전 기간 동안 공모전 접수 작품들은 자동 설정 숨김
                                            isSchoolCompetitionModifiable  isSchoolCompetitionSubmitted
                                    접수작             false                          true
                                    반려작             true                           false */}
                                {!(isOnSubmission && (submitInfos.isSchoolCompetitionModifiable || submitInfos.isSchoolCompetitionSubmitted)) && <>
                                    <TextCheckBox
                                        text={"등록된 원고에서 자동 설정할게요."}
                                        isChecked={isAutoAdCut}
                                        onChange={ (e) => setTitleRegistrationInfo( prev => ({...prev, isAutoAdCut: !prev.isAutoAdCut}) ) }
                                        style={{
                                            width: '100%',
                                            gap: 8, padding: 0, borderWidth: 0,
                                            fontSize: '0.875rem',
                                            color: 'rgb(189, 189, 194)',
                                            backgroundColor: 'rgb(255,255,255)'
                                        }}
                                        className={'Small'}
                                    />
                                </>}
                            </MultiUploadImageDisplayed>
                        </WithLabel>
                        <WithLabel label={'대표 이미지'} >
                            <SingleUploadImageDisplayed
                                width={110} height={110}
                                files={thumbnail}
                                setFiles={(files) => setTitleRegistrationInfo( prev => ({...prev, thumbnail: files}))}
                                screenSubtitle={["600px * 600px"]}
                            />
                        </WithLabel>
                    </div>
                    <div
                        id={sectionContent[2][0]}
                        className={'AdditionalInfo'}
                    >
                        <SectionHeader content={`3. ${sectionContent[2][1]}`} subTitle={sectionSubtitles[sectionContent[2][1]]} isNecessary={isSectionNecessary[sectionContent[2][1]]} />
                        <WithLabel label={'작품 유형'}>                            
                            <RadioGroup< "story" | "episode" > 
                                value={storyType} fullWidth
                                options={storyOptions}
                                onChange={(value) => setTitleRegistrationInfo(prev => ({...prev, storyType: value}))}
                            />
                        </WithLabel>
                        <WithLabel label={'성인 컨텐츠 여부'} >
                            <TextCheckBox
                                text={"성인 컨텐츠에요."}
                                isChecked={isAdult}
                                onChange={ (e) => setTitleRegistrationInfo( prev => ({...prev, isAdult: !prev.isAdult}) ) }
                                style={{width: '100%'}}
                            />
                        </WithLabel>
                        <WithLabel label={'장르'}>
                            <Dropdown 
                                itemList={[
                                    '일상', '스릴러', '무협/사극',
                                    '드라마', '개그', '판타지',
                                    '스포츠', '액션', '감성',
                                    '로맨스', 'BL', 'GL',
                                ]}
                                value={genre}
                                onClick={(value) => {
                                    if ( genre.length > 2 && !genre.includes(`${value}`) ) {
                                        toastAlert("장르는 최대 3개까지 선택할 수 있어요.", 3000);
                                        return
                                    }
                                    
                                    if ( genre.includes(`${value}`) ) {
                                        setTitleRegistrationInfo(prev => ({ ...prev, genre: prev.genre.filter( genre => genre !== value ) })) 
                                    } else {
                                        setTitleRegistrationInfo(prev => ({ ...prev, genre: [ ...prev.genre, `${value}` ] }))
                                    }
                                }} 
                            />
                        </WithLabel>
                        <WithLabel label={'줄거리'}>
                            <TextArea
                                text={titleRegistrationInfo.description}
                                onChange={(value) => setTitleRegistrationInfo( prev => ({ ...prev, description: value }))}
                                rows={5}
                                maxLength={400}
                                fullWidth
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                forcedGuideTextType={hasTriedApplying && titleRegistrationInfo.description.length === 0 ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'해시태그'}>
                            <Hashtag 
                                tags={tags}
                                setTags={(value) => setTitleRegistrationInfo(prev => ({ ...prev, tags: value }) ) }
                            />
                        </WithLabel>
                    </div>
                    <hr className={'HorizontalLine'} />
                </div>
                {!isEditing
                    ?<div className={'SubmitLine'} >
                        <Button 
                            className={`SubmitButton`} 
                            onClick={(e)=>{
                                (async()=>{
                                    setIsLoading(true);
                                    
                                    if ( basicInfoStatus !== 'success' ) {
                                        toastAlert("모든 필수정보를 입력해 주세요.")
                                        setHasTriedApplying(true);
                                        setIsLoading(false);
                                        return;
                                    }

                                    const addressid = session.addressid;
                                    const themeColor = 'rgb(255,255,255)';
                                    const themeTemplate = 'circle';

                                    const selectedTags = tags.slice(0,2);
                                    const unSelectedTags = tags.slice(2);
                                    const newTags = [...selectedTags, ...unSelectedTags.filter( tag => !selectedTags.includes(tag))];
                                    const newThumbnail = thumbnail[0]?.image || '';

                                    const newTitle = {
                                        serviceId: addressid, titleId, title,
                                        tags: genre.join(','), description, thumbnail: newThumbnail,
                                        keywords: newTags.join(','), isAdult,
                                        themeColor, themeTemplate, slides: adCuts, storyType, exposure
                                    }

                                    const res = await createTitle(newTitle);

                                    if (res) {
                                        setDialogStatus( prev => ({...prev, postNewOpen: true}) );
                                        logBehavior('create-title', newTitle);
                                    } else {
                                        toastAlert('작품 등록에 실패했습니다.');
                                    }
                                    
                                    setIsLoading(false);
                                })()
                            }}
                        >
                            {'작품 등록하기'}
                        </Button>
                    </div>
                    :<div className={'SubmitEditingLine'} >
                        { isDeleteVisible && <Button 
                            className={'Delete EditingButton'}
                            onClick={() => setDialogStatus(prev => ({...prev, deleteOpen: true}))}
                        >
                            {'삭제'}
                        </Button>}
                        <Button className={'Cancel EditingButton'} 
                            onClick={ () => fn.goto(`/contents/${thisTitleServiceId}/${propTitleId}`) }
                        >
                            {`이전`}
                        </Button>
                        { isEditVisible && <Button className={'Modify EditingButton'} 
                            onClick={() => {
                                (async()=>{
                                    if (basicInfoStatus !== 'success') {
                                        toastAlert('필수 정보를 입력해 주세요.', 2000);
                                        return;
                                    };

                                    const isSubmitAgain = submitInfos.isSchoolCompetitionModifiable && isOnSubmission && isThisYearSchoolCompetition;

                                    const serviceId = thisTitleServiceId;
                                    const slides = adCuts;

                                    const selectedTags = tags.slice(0,2);
                                    const unSelectedTags = tags.slice(2).filter(tag => !selectedTags.includes(tag));
                                    
                                    const newKeywords = [...selectedTags, ...unSelectedTags].join(',');
                                    const themeColor = 'rgb(255,255,255)';
                                    const themeTemplate = 'circle';
                                    const newThumbnail = thumbnail[0]?.image || '';
                                    const filteredExposure = isSubmitAgain?'public':exposure;

                                    const updateRes = await updateTitle({
                                        serviceId, titleId,
                                        title, tags:genre.join(','), description, thumbnail: newThumbnail,
                                        keywords: newKeywords, isAdult,
                                        slides, themeColor, themeTemplate,
                                        storyType, exposure: filteredExposure
                                    });

                                    refreshTitle([{serviceId, titleId}]);

                                    if (oldExposure && oldExposure !== exposure){
                                        logBehavior('change-exposure(save)', {
                                            prevExposure: oldExposure,
                                            currExposure: exposure
                                        });
                                    }
                                    flags.didTitleUpdated = true;
                                    if ( !isSubmitAgain ) {
                                        if ( updateRes ) {
                                            fn.goto(`/contents/${thisTitleServiceId}/${propTitleId}`);
                                            localStorage.setItem('previousurl', `/contents/${thisTitleServiceId}/${propTitleId}`);
                                        } else {
                                            toastAlert('작품 수정을 실패했어요.');
                                        }
                                    } else {

                                        // 재접수 페이지 보내기 
                                        fn.goto(`/analysis/univapply/${serviceId}/${titleId}`);

                                        setIsLoading(false);
                                    }
                                })()
                            }}
                        >
                            {editLabel}
                        </Button>}
                    </div>}
            </div>
            <Modal 
                open={dialogStatus.postNewOpen}
                onClose={ () => fn.goto(`/contents/${thisTitleServiceId?thisTitleServiceId:session.addressid}/${titleRegistrationInfo.titleId}`)}
                content={<div style={{lineHeight: '30px', padding: '10px 0  ', whiteSpace: 'pre-line'}} >
                    {'작가님의 멋진 작품을 등록했어요!🎉\n바로 1화를 올릴까요?'}
                </div>}
                actions={<>
                    <Button autoFocus onClick={()=>fn.goto(`/contents/${thisTitleServiceId?thisTitleServiceId:session.addressid}/${titleRegistrationInfo.titleId}`)} style={{color: 'black'}} >
                        {'다음에'}
                    </Button>
                    <Button autoFocus onClick={() => {
                        fn.goto(`/enroll/${thisTitleServiceId?thisTitleServiceId:session.addressid}/${titleRegistrationInfo.titleId}/1`)
                    }} color={"primary"} >
                        {'1화 올리기'}
                    </Button>
                </>}
                styles={{ 
                    actions: { justifyContent: 'space-evenry' } 
                }}
            />
            <Modal
                open={dialogStatus.deleteOpen}
                onClose={ () => setDialogStatus(prev => ({...prev, deleteOpen: false}) ) }
                title={'작품을 삭제하시겠어요?'}
                content={`<${title}> 의 모든 정보가 삭제됩니다.`}
                actions={<>
                    <Button autoFocus onClick={() => setDialogStatus(prev => ({...prev, deleteOpen: false}) )} style={{padding: '6px 12px', width: '80px', color: 'black'}} >
                        {'취소'}
                    </Button>
                    <Button autoFocus variant={"contained"} color={"secondary"} style={{padding: '6px 12px', width: '80px', backgroundColor: 'rgb(219,0,0)'}}
                        onClick={ async () => {
                            const serviceId = thisTitleServiceId;
                            const deleteKey = await deleteTitle({serviceId, titleId})

                            if ( deleteKey.length > 0 ) {
                                logBehavior('delete-title(success)', {serviceId, titleId});
                                setDialogStatus(prev =>({...prev, deleteOpen: false, deleteInformOpen: true}) );
                            } else {
                                logBehavior('delete-title(fail)', {serviceId, titleId});
                                setDialogStatus(prev => ({...prev, deleteOpen: false}) );
                                toastAlert("작품 삭제를 실패했어요.");
                            }
                        }} >
                        {'삭제'}
                    </Button>
                </>}
                styles={{
                    content: { paddingTop: 0, border: 0 },
                    actions: { padding: '0px 15px 15px 0' }
                }}
            />
            <Modal
                open={dialogStatus.deleteInformOpen}
                onClose={() => {
                    setDialogStatus(prev => ({...prev, deleteInformOpen: false}) );
                    fn.goto('/');
                }}
                title={'작품 삭제'}
                content={`<${title}> 을 삭제했어요.`}
                actions={
                    <Button autoFocus color={"secondary"} style={{padding: '6px 12px', width: '80px'}}
                        onClick={ () => {
                            fn.goto(`/`)
                            setDialogStatus(prev => ({...prev, deleteInformOpen: false}) )
                            isTitleModified=false;
                        }} 
                    >
                        {'확인'}
                    </Button>}
                styles={{
                    content: { paddingTop: 0, border: 0 },
                    actions: { display: 'flex', justifyContent: 'center' }
                }}
            />
            <Modal 
                open={dialogStatus.backwardWarning}
                onClose={ () => setDialogStatus( prev => ({ ...prev, backwardWarning: false })) }
                title={'나가시겠어요?'}
                content={'변경사항이 저장되지 않습니다.'}
                actions={<>
                    <Button onClick={ () => {
                        setDialogStatus( prev => ({ ...prev, backwardWarning: false }))
                    }} style={{color: 'black'}} >
                        {'취소'}
                    </Button>
                    <Button variant={'contained'} color={"primary"}
                        onClick={ async () => {
                            setDialogStatus( prev => ({ ...prev, backwardWarning: false }));
                            isTitleModified = false;
                            fn.goto(`/contents/${propServiceId}/${titleId}`);
                        }} >
                        {'확인'}
                    </Button>
                </>}
                styles={{
                    content: { paddingTop: 0, border: 0 },
                    actions: { display: 'flex', justifyContent: 'center' }
                }}
                
            />
            <LoadingCircle show={isLoading} />
        </div>
    )
}

const SectionHeader = ({content, subTitle, isNecessary} : {content: string, subTitle?: string, isNecessary: boolean}) => {
    return (
        <div className={'SectionHeader'}>
            <div className={'HeaderLine'}>
                <span>{content}</span>
                {isNecessary
                    ?<div className={'NecessarySection'} >{'필수 항목'}</div>
                    :<div className={'OptionalSection'} >{'선택 항목'}</div>}
            </div>
            <div className={'SubtitleLine'} >
                {subTitle || ""}
            </div>
        </div>
    )
}

const Modal = ({ 
    open, onClose, styles={}, title, content, actions
}:{
    open: boolean, onClose: () => void,
    styles?: {
        dialog?: CSSProperties,
        title?: CSSProperties,
        content?: CSSProperties,
        actions?: CSSProperties
    }
    title?: ReactNode,
    content?: ReactNode,
    actions?: ReactNode,
}) => {
    return(
        <Dialog open={open} onClose={onClose} style={styles?.dialog || {}} >
            {title?<DialogTitle style={styles?.title || {}} >
                {title}
            </DialogTitle>:<></>}
            {content?<DialogContent style={styles?.content || {}} >
                {content}
            </DialogContent>:<></>}
            {actions?<DialogActions style={styles?.actions || {}} >
                {actions}
            </DialogActions>:<></>}
        </Dialog>
    )
}

export default PostTitle;