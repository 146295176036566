import { Tab, Tabs } from "@material-ui/core";
import { useState, useEffect } from "react";
import LoadingCircle from "../../Component/LoadingCircle";
import TabPanel from "../../Component/TabPanel";
import SearchResult from "./Components/SearchResult";
import './Styles/index.scss';
import { useSearchTitleList } from "../../Recoil/Search/Search";
import SearchField from "./Components/SearchField";
import { executeSearch } from "./Util/Functions";
import { pageHeaderOptions } from "../../Recoil/PageHeader/PageHeader";
import { useSetRecoilState } from "recoil";

const TAB_NAMES = {
    title: '웹툰', 
    author:'작가', 
    tags: '장르'
};

const TAGS = {
    daily_routine: '일상', comedy: '개그', fantasy: '판타지', action: '액션',
    drama: '드라마', romance: '로맨스', sensibility: '감성', thriller: '스릴러', 
    sport: '스포츠', martial_arts_historical_drama: '무협/사극', BL: 'BL', GL: 'GL',
};

const limit = 999;

const Search = ({query}:{query: string}) => {
    
    const [ queryOnEditing, setQueryOnEditing ] = useState<string>("");
    const [ tabPage, setTabPage ] = useState<number>(0);

    const searchParams = new URLSearchParams(query);
    const searchedQuery = searchParams.get('query') || '';
    const searchedType = searchParams.get('type') || 'title';
    
    const { searchTitleList, fetch: fetchSearchTitleList } = useSearchTitleList();
    const titles = searchTitleList[`${searchedQuery}:${searchedType}`] || [];
    const isLoading = searchTitleList[`${searchedQuery}:${searchedType}`] === null;
    
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    useEffect(() => {
        setPageHeader({
            titleText: searchedQuery?`오늘의웹툰: ${searchedQuery} 검색결과`:'오늘의웹툰',
            isHiddenForMobile: true,
        })
    }, [searchedQuery, setPageHeader]);

    useEffect(() => {
        if ( searchedQuery ) {
            setQueryOnEditing(searchedQuery)
        };

        if ( searchedType ) {
            setTabPage(Object.keys(TAB_NAMES).indexOf(searchedType))
        };

        if (searchedQuery && searchedType) {
            fetchSearchTitleList(searchedQuery, searchedType, limit);
        }
    },[fetchSearchTitleList, searchedQuery, searchedType])
    
    return(
    <div className={'Body'}>
        <div className={'SearchArea'} >
            <div className={'SearchHeader'} >
                <SearchField
                    type={searchedType} 
                    query={searchedQuery}
                    limit={limit}
                    setCurrentText={setQueryOnEditing} 
                />
            </div>
            <div className={'SearchBody'} >
                {searchedQuery
                ?<div style={{width: '100%'}}>
                    <Tabs 
                        style={{borderBottom: '1px solid rgb(241,241,241)'}} centered
                        TabIndicatorProps={{ style: {backgroundColor: "transparent"} }} indicatorColor={"primary"}
                        value={tabPage}
                    >
                        {Object.entries(TAB_NAMES).map( ([key, label], i) => (
                            <Tab 
                                key={`Tab:${key}`} disableRipple={true}
                                label={
                                    <span className={`${tabPage === i?'FocusedTab':''}`}>
                                        {label}
                                    </span>
                                }
                                onClick={ () => executeSearch(queryOnEditing, key, limit) }
                            />
                        ))}
                    </Tabs>
                    {Object.keys(TAB_NAMES).map( (tabName, index) => (
                        <TabPanel value={tabPage} index={index} key={`TabPanel:${tabName}:${searchedType}:${queryOnEditing}`}>
                            <SearchResult 
                                titles={
                                    tabPage === index
                                        ?titles
                                        :[]
                                } 
                                isLoading={isLoading} 
                            />
                        </TabPanel>
                    ))}
                </div>:<></>}
                { (  searchedQuery !== '' && titles.length === 0 && searchedType === 'tags' ) 
                  || searchedQuery === ''
                ?<div className={"QueryRecommendations"}>
                    <div className={'TitleBox'} >
                        <div style={{textAlign: 'center'}}>{'장르별 검색'}</div>
                    </div>
                    <div className={'GenreGridBox'}>
                        {Object.entries(TAGS).map(([key, label],idx, arr) => (
                            <div
                                className={'GenreCell'} key={`tagsSearch:${key}`}
                                style={{ marginBottom: `${Math.floor(idx / 4) === Math.floor((arr.length - 1) / 4)?'0px':'15px' }`}}
                            >
                                <span
                                    className={`Genre`}
                                    onClick={ ()=> executeSearch(label, 'tags', limit) }
                                >
                                    {label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>:<></>}
            </div>
        </div>
        <LoadingCircle show={isLoading} />
    </div>
    )
}

export default Search;
