import { currentSchoolCompetitionYear, isOnSubmission, isLockedMonth, isUnderReview } from "../../Analysis/Univ2023/Data/Variables";

const VersatileBadge = ({year, winner}:{year: string, winner: string|null}) => {
    
    const isYearLabelOpen   = isOnSubmission || isUnderReview;
    const isWinnerLabelOpen = isLockedMonth;
    
    if ( year !== currentSchoolCompetitionYear ) {
        return <></>;
    }

    if ( !isWinnerLabelOpen && !isYearLabelOpen ) {
        return <></>;
    }

    if ( isWinnerLabelOpen && (!winner || winner === 'fail')) {
        return <></>;
    }

    if ( isWinnerLabelOpen && winner ) {
        return( <div className={'VersatileBadgeComponent'}>
            <img src={`https://static.webtoon.today/ddah/icon/badge_${winner}.png`} alt={winner} 
                style={{
                    position: "absolute",
                    bottom: 0, right: 0,
                    zIndex: 1, width: 149, height: 149
                }}
            />
        </div>);
    }

    return( <div className={'VersatileBadgeComponent'}>
        <img src={`https://static.webtoon.today/ddah/icon/submission_complete_badge.png`} alt={"submission_complete_badge"} 
            style={{
                position: "absolute",
                bottom: 0, right: 0,
                zIndex: 1, width: 149, height: 149
            }}
        />
    </div>);
}

export default VersatileBadge;
