import { TextField, FormControlLabel, Button, Checkbox } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';
import './SignIn.scss';
import { fn } from '../../Functions';

import { readAnalyticsSubmitInfo } from '../../Data/AnalyticsSubmit';
import { checkSurveyResponseExists } from '../../Data/Survey';
import LoadingCircle from '../../Component/LoadingCircle';
import { useSignIn } from '../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { currentSchoolCompetitionYear } from '../../Route/Analysis/Univ2023/Data/Variables';


const ResetPassword = () => {
    const alert = fn.alert;
    const { EmailSignin } = useSignIn();

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '비밀번호 재설정',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'},
            mobileShortcuts:<></>
        })
    },[setPageHeader])

    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ email, setEmail ] = React.useState(null);
    const [ otp, setOtp ] = React.useState(null);
    const [ password, setPassword ] = React.useState(null);
    const [ isPasswordVisible, setIsPasswordVisible ] = React.useState(false);
    const [ reCheckPassword, setReCheckPassword ] = React.useState(null);
    const [ isReCheckPasswordVisible, setIsReCheckPasswordVisible ] = React.useState(false);
    const [ redirectTo, setRedirectTo ] = React.useState(null);

    React.useEffect(()=>{
        let queryString = window.location.search.replace('?','');
        let queries = queryString?Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri)))):{};
        if ('email' in queries){
            setEmail(queries.email);
        }

        if ('otp' in queries){
            setOtp(queries.otp);
        }

        if ('redirectTo' in queries) {
            setRedirectTo(queries.redirectTo);
        }
    },[])

    const checkEnglish = (password) => {
        const regx = /[a-zA-Z]/;
        return regx.test(password)
    }

    const checkNumber = (password) => {
        const regx = /[0-9]/;
        return regx.test(password)
    }

    const checkKorean = (password) => {
        const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        return regx.test(password)
    }

    const [ hasKoreanLetters, setHasKoreanLetters ] = React.useState(false);

    return(
        <div className={'MainArea'} >
            <div className={'BodyContainer'}>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'이메일'}
                    </div>
                    <form>
                        <TextField fullWidth disabled value={email || ''} type={'text'}  
                            onChange={(e)=>{}} 
                            inputProps={{
                                style:{fontSize: '0.9rem'},
                            }}
                        />
                    </form>
                    <div className={'BelowField'} >
                        {" "}
                        
                    </div>
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'비밀번호'}
                    </div>
                    <div>
                        <TextField fullWidth value={password || ''} type={`${isPasswordVisible?'text':'password'}`}  
                            onChange={(e)=>{

                                const hasKorean = checkKorean(e.target.value)
                                if (hasKorean) {
                                    setHasKoreanLetters(true);
                                } else {
                                    setHasKoreanLetters(false);
                                }

                                setPassword(e.target.value)
                            }} 
                            inputProps={{
                                style:{fontSize: '0.9rem'},
                            }}
                            placeholder={'비밀번호를 입력해주세요.'}
                        />
                    </div>
                    <div className={'BelowField'} >
                        {!hasKoreanLetters
                        ?<div className={'PasswordRuleCheck'} >
                            <div className={`CheckText ${checkEnglish(password) && password?'Checked':''}`} >
                                {'영문'}<Check className={'CheckIcon'} />
                            </div>
                            <div className={`CheckText ${checkNumber(password) && password?'Checked':''}`} >
                                {'숫자'}<Check className={'CheckIcon'}  />
                            </div>
                            <div className={`CheckText ${password && password.length>=6 && password.length<=20?'Checked':''}`} >
                                {'6-20 자리'}<Check className={'CheckIcon'} />
                            </div>
                        </div>:
                        <div style={{color: 'red', fontSize: '0.75rem'}}>
                            {'영문, 숫자, 특수문자만 입력할 수 있어요.'}
                        </div>}
                        <div className={'ShowingPassword'} >
                            <FormControlLabel control={
                                <Checkbox className={'VisibleCheckBox'} color={'primary'}
                                    checked={isPasswordVisible} onChange={(e)=>setIsPasswordVisible(e.target.checked)}
                                />
                            } label={'비밀번호 표시'} />
                        </div>
                    </div>
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'비밀번호 확인'}
                    </div>
                    <div >
                        <TextField fullWidth value={reCheckPassword || ''} type={`${isReCheckPasswordVisible?'text':'password'}`} onChange={(e)=>{
                                setReCheckPassword(e.target.value)
                            }} 
                            error={(reCheckPassword && password !== reCheckPassword)?true:false}
                            inputProps={{style:{fontSize: '0.9rem'}}}
                            placeholder={'다시 한번 입력해주세요.'}
                            onKeyDown={ (e) => {
                                if (e.key === 'Enter') {
                                    document.querySelector('.NextModeButton').click();
                                }
                            }}
                        />
                    </div>
                    <div className={'BelowField'}>
                        <div className={'CorrespondState'}>
                            { reCheckPassword && password !== reCheckPassword?'비밀번호가 달라요! 확인해보시겠어요?':''}
                        </div>
                        <div className={'ShowingPassword'} >
                            <FormControlLabel control={
                                <Checkbox className={'VisibleCheckBox'} color={'primary'}
                                    checked={isReCheckPasswordVisible} onChange={ (e) => setIsReCheckPasswordVisible(e.target.checked) }
                                />
                            } label={'비밀번호 표시'} />
                        </div>
                    </div>
                </div>
                <Button variant={'contained'} color={'primary'} className={'NextModeButton'} disabled={hasKoreanLetters || !password || !checkNumber(password) || !checkEnglish(password) || 
                    password !== reCheckPassword || password < 6 || password > 20 }
                    onClick={()=> {

                        (async()=>{
                            setIsLoading(true);

                            let signinRes = await EmailSignin({email, otp, password});
                            if (signinRes){
                                let purposedQuery = /report\/([^/]+)\/([^/]+)/.exec(redirectTo || null);
                                if (purposedQuery && purposedQuery[1] && purposedQuery[2]) {
                                    let [, serviceId, titleId] = purposedQuery
                                    const response = await readAnalyticsSubmitInfo({serviceId, titleId});

                                    if (response && response.isSchoolCompetitionSubmitted && response.schoolCompetitionSubmittedYear === currentSchoolCompetitionYear) {

                                        const searchParams = new URLSearchParams(window.location.search);
                                        const redirectAddress = searchParams.get('redirectTo'); 
                                        let didSurvey = await checkSurveyResponseExists('2022univ');
                                        if (didSurvey?.isResponseExists) {
                                            // 이미 설문을 했으므로 리포트 페이지로 이동
                                            fn.goto(`${redirectAddress}`)
                                        } else {
                                            // 리디렉트 값을 달고 설문조사 페이지로 이동
                                            fn.goto(`/survey/2022univ${redirectTo?`?redirectTo=${redirectTo}`:''}`)
                                        }
                                        setIsLoading(false);
                                        return;
                                    };
                                };
                                
                                fn.goto(`/signin${redirectTo?`?redirectTo=${redirectTo}`:''}`);
                            }else {
                                alert('비밀번호 변경에 실패했습니다.')
                            };

                            setIsLoading(false);
                        })();
                    }}
                >{'완료'}</Button>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default ResetPassword;