import React, { useEffect, useState } from 'react';
import Feed from './Feed';
import Tabs from './Tabs';
import Footer from './Footer';
import MoreButton from './MoreButton';

import './Styles/HomePc.scss';
import { useFeedTitleList } from '../../../Recoil/Title/Titles';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../Recoil/PageHeader/PageHeader';

const HomePC = ({ selectedTab } : { selectedTab: string, }) => {
    const [ isFloatingTabBarDisplayed, setIsFloatingTabBarDisplayed ] = useState<boolean>(false);
    const [ startIndex, setStartIndex ] = useState<number>(0);
    const [ endIndex, setEndIndex ] = useState<number>(12);
    const { feedData: titleList, secureFetch, isExhausted } = useFeedTitleList(selectedTab);

    const [ waitingFor, setWaitingFor ] = useState<'refresh'|'more'|null>(null);
    
    const renderableTitlelist = titleList?.slice(startIndex, endIndex);
    
    const isEnd = isExhausted && titleList && titleList.length <= endIndex;
    
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    useEffect( () => {
        setPageHeader({
            titleText: '오늘의웹툰',
            isStickyTop: true,
        })
    },[selectedTab, setPageHeader])

    useEffect(() => {
        setStartIndex(0);
        setEndIndex(12);
    }, [selectedTab]);

    useEffect(()=>{
        if (titleList && endIndex >= titleList?.length){
            secureFetch(endIndex);
        }
    },[endIndex, titleList, secureFetch])

    useEffect(()=> {
        if (waitingFor === 'refresh'){
            setStartIndex(endIndex);
            setEndIndex(endIndex + 12);
        }else if (waitingFor === 'more') {
            setEndIndex(endIndex + 12);
        }
        setWaitingFor(null);

        if (   endIndex <= 12 && titleList 
            && titleList[0]?.serviceId === 'today' 
            && titleList[0]?.titleId === 'univ2023' ){
            setStartIndex(0);
            setEndIndex(24);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endIndex, titleList]);
    
    const handleRefresh = () => {
        if (isEnd){
            setStartIndex(0);
            setEndIndex(12);
            return;
        }

        setWaitingFor('refresh');
        secureFetch(endIndex + 12);
        if (titleList && endIndex + 12 <= titleList?.length){
            setWaitingFor(null);
            setStartIndex(endIndex);
            setEndIndex(endIndex + 12);
        }
    }
    
    const handleMore = () => {
        setWaitingFor('more');
        secureFetch(endIndex + 12);
        if (titleList && endIndex + 12 <= titleList?.length){
            setWaitingFor(null);
            setEndIndex(endIndex + 12);
        }
    }

    useEffect(()=>{
        const debounce = (func: Function) => {
            let scrollTimeoutId: number;
            return function(){
                clearTimeout(scrollTimeoutId);
                scrollTimeoutId = setTimeout(func, 50);
            }
        }

        const handleScroll = debounce(()=>{
            const scrollTop = window.scrollY;
            const prevScrollPos = Number(document.getElementsByTagName('body')[0]?.getAttribute("data-scroll") || 0);
            
            if(scrollTop >= 74){
                document.getElementsByTagName('body')[0]?.setAttribute("data-scroll-have-passed-threshold", "yes");
            } else if(scrollTop === 0){
                document.getElementsByTagName('body')[0]?.removeAttribute('data-scroll-have-passed-threshold');
            }
            
            const scrollDirection = scrollTop > prevScrollPos ? 'down' : 'up';
            if(scrollTop >= 58 && scrollDirection === 'down'){
                setIsFloatingTabBarDisplayed(false);
            } else{
                setIsFloatingTabBarDisplayed(true);
            }
            
            document.getElementsByTagName('body')[0]?.setAttribute("data-scroll", `${scrollTop}`);
        });

        window.addEventListener('scroll', handleScroll);
        
        return ()=>{
            window.removeEventListener('scroll', handleScroll);
            document.getElementsByTagName('body')[0]?.removeAttribute('data-scroll-have-passed-threshold');
            document.getElementsByTagName('body')[0]?.removeAttribute('data-scroll');
        }
    }, []);
    
    return (
        <div className={'HomePcArea'} >
            <Tabs
                selectedTab={selectedTab}
                handleRefresh={handleRefresh}
                className={isFloatingTabBarDisplayed ? "Show" : ""}
            />
            {renderableTitlelist && 
                <Feed 
                    data={renderableTitlelist}
                    selectedTab={selectedTab}
                />}
            {!isEnd && <MoreButton handleMoreButton={handleMore}/>}
            <Footer />
        </div>
    );
};

export default HomePC;