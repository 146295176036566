
import { Button, IconButton } from '@material-ui/core';
import { Search, KeyboardArrowLeft, SearchOutlined, AccountCircle, MenuOutlined, Settings, Close } from '@material-ui/icons';
import React, { SetStateAction, useEffect } from 'react';
import './Header.scss';
import {flags, fn} from './Functions';
import CheckLogout from './Page/CheckLogout';
import ChannelService from './Component/ChannelService';
import AnchorButton from './Component/AnchorButton';
import { useRecoilValue } from 'recoil';
import { useSession, useSignIn } from './Recoil/Session/Session';
import { pageHeaderOptions } from './Recoil/PageHeader/PageHeader';

const PopupMenu = ({ 
    isOpen, setIsOpen, device = 'pc' 
}:{
    isOpen: boolean, setIsOpen: React.Dispatch<SetStateAction<boolean>>, device: 'pc' | 'mobile'
}) => {
    const { session } = useSession();
    const { logout } = useSignIn();
    if (!isOpen) return <></>;

    return(<div className={`PopupMenuArea ${device}`}>
        {device === 'mobile'?<div className={'PopupCloseButton'} onClick={() => setIsOpen(false)} >
            <Close />
        </div>:<></>}
        <div className={`PopupContainer ${device}`} >
            <div className={'SettingIcon'}>
                <AnchorButton href={`/account`} onClick={()=>setIsOpen(false)} >
                    <Settings />
                </AnchorButton>
            </div>
            <div className={'Author'} >
                <div className={'AuthorImage'} onClick={()=>{
                    setIsOpen(false);
                    fn.goto('/account');
                }} >
                    <img style={{width:78, height:78, borderRadius: '50%'}} src={session.image && session.image !== 'https://static.webtoon.today/noimage.jpg'?session.image:'https://static.webtoon.today/noprofile.jpg'} alt={session.name || undefined}/>
                </div>
                {session.memberState === 'regular'
                    ?<div className={'AuthorName'}>
                        {`${session.name}`}
                    </div>:<div className={'UnregisterInfo'} >
                        <AnchorButton href={`/signin`} onClick={() => setIsOpen(false)} >
                            {'로그인'}
                        </AnchorButton> 
                    </div>}
                {device === 'mobile'
                ?<div className={'AddWebtoonBox'}>
                    <AnchorButton 
                        href={`/enroll`}
                        onClick={()=>setIsOpen(false)}
                        className={'AddWebtoonButton'}
                    >
                        {'작품 등록'}
                    </AnchorButton>
                </div>
                :<></>}
            </div>
            <div className={'MenuList'}>
                {Object.entries({
                    "포트폴리오": `/portfolio/${session.addressid || ''}`,
                    "관심 웹툰 / 관심 작가 / 책갈피": `/mypage`,
                }).map( ([label, address]) => (
                    <div className={'MenuRow'} key={`${label}${address}`} onClick={() => setIsOpen(false)} >
                        <AnchorButton 
                            href={address} 
                            onClick={()=>setIsOpen(!isOpen)} 
                            className={'Label'}
                        >
                            {label}
                        </AnchorButton>
                    </div>
                ))}
                <div className={'DividerMenuLine'} />
                {session.memberState === "regular"
                    ?<div className={'LogoutMenu'} onClick={ () => {
                        (async () => {
                            setIsOpen(false);;
                            logout();
                            fn.goto('/');
                        })()
                    }} >
                        {'로그아웃'}
                    </div>
                    :<></>}
                <div className={'InquiryMenu'} onClick={ ()=> {
                    ChannelService.ChannelIO()('openChat');
                    setIsOpen(false);
                } }>
                    {'문의하기'}
                </div>
            </div>
        </div>
    </div>)
}

const PageMovingMenu = ({isOpen, setIsOpen}:{isOpen: boolean, setIsOpen: React.Dispatch<SetStateAction<boolean>>}) => {
    if (!isOpen) return <></>;

    return(<div className={'PageMovingMenuArea'}>
        <div className={'PageMovingMenuCloseButton'} onClick={() => setIsOpen(false)} >
            <Close />
        </div>
        <div className={'PageMovingMenuContainer'} >
            {Object.entries({
                "웹툰": `/`,
                "서비스 소개": `/analysis`,
                "공지사항": "/notice",
                "웹툰 메트릭": `https://metric.webtoon.today`,
                "작품 분석 신청": `/analysis/application`,
                '과제 부활전': "/analysis/univ2023"
            }).map(([label, href]) => (<div className={'ButtonBox'}>
                <AnchorButton
                    href={href} onClick={() => setIsOpen(false)} 
                    className={'PageMovingButton'}
                    key={`Page:${label}:${href}`}
                >
                    {label}
                </AnchorButton>
            </div>))}
        </div>
    </div>)
}

const Header = ({isClient} : {isClient: boolean}) => {
    const { session, sessionRefresh } = useSession();
    const pageHeader = useRecoilValue(pageHeaderOptions);
    
    const [ isLogout, setIsLogout ] = React.useState(false);
    const [ isPopupOpen, setIsPopupOpen ] = React.useState(false);
    const [ isPageMenuOpen, setIsPageMenuOpen ] = React.useState(false);

    const {
        titleText, isBackable, isStickyTop,
        isHiddenForPC, isHiddenForMobile,
        headerStyle,
        isTitleHidden,
        mobileShortcuts,
    } = pageHeader

    useEffect( () => {
        window.document.title = '오늘의웹툰';
    },[])

    useEffect( () => {
        const handleClickOutside = (e: Event): void => {
            if (isPopupOpen && !(e.target as HTMLElement).closest('.PopupContainer')) {
                setIsPopupOpen(false);
            };
            if (isPageMenuOpen && !(e.target as HTMLElement).closest('.PageMovingMenuContainer')) {
                setIsPageMenuOpen(false);
            };
        }
        window.addEventListener('click', handleClickOutside)

        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [isPopupOpen, isPageMenuOpen])

    useEffect(() => {
        const body = document.querySelector('body')
        if ( window.innerWidth < 700 ) {
            if ( !(isPopupOpen || isPageMenuOpen) && body ) {
                body.removeAttribute('style');
            } else if ( (isPopupOpen || isPageMenuOpen) && body ) {
                body.setAttribute('style', "max-height: 100vh");
                body.setAttribute('style', "overflow: hidden");
            };
        } else {
            body && body.removeAttribute('style');
        }
        return () => {
            body && body.removeAttribute('style');
        }
    }, [isPopupOpen, isPageMenuOpen]);

    useEffect(()=>{
        (async()=>{
            await sessionRefresh();
        })();
    }, [sessionRefresh])

    useEffect(()=>{
        if(isClient){
            ChannelService.boot({
                "pluginKey": "381d022e-d3e3-4426-a4a9-9cb73de0d659", //please fill with your plugin key
                "memberId": session.userid,
                "profile": {
                    "name": session.name,
                    "email": session.email,
                },
            });

            ChannelService.ChannelIO();
        }

        flags.isClient = isClient;
        
    },[isClient, session.email, session.name, session.userid]);

    return(
    <div className={'HeaderArea'} style={isStickyTop?{position: 'sticky', top: 0, zIndex: 9, background: 'rgb(255,255,255)'}:{}} >
        <div className={'PCHeaderContainer'} style={isHiddenForPC?{display:'none'}:{}}>
            <div className={'PageMoveBox'}>
                <a className={'WebtoonTodayLogo'} href={'/'} onClick={fn.gotoByAnchor} >
                    <img src={'https://static.webtoon.today/ddah/logo-03.png'} alt={'logo'} style={{height: 32, objectFit: 'contain'}} />
                </a>
                <div className={'MovePageMenus'} >
                    {Object.entries({
                        '웹툰': '/',
                        '서비스 소개': `/analysis`,
                        "공지사항": "/notice",
                        "웹툰 메트릭": `https://metric.webtoon.today`,
                        '작품 분석 신청': `/analysis/application`,
                        '과제 부활전': "/analysis/univ2023"
                    }).map( ([label, href]) => (
                        <AnchorButton key={`${label}:${href}`} href={href} className={'MoveButton'}>
                            {label}
                        </AnchorButton>
                    ))}
                </div>
            </div>
            <PopupMenu isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} device={'pc'} />
            <div className={'SearchAndLoginBox'} >
                {!window.location.pathname.startsWith('/search')
                    ?<a className={'SearchButton'} href={'/search'}>
                        <Search onClick={fn.gotoByAnchor} />
                    </a>
                    :<></>}
                <div className={'MenuIcon'} onClick={ () => setIsPopupOpen(!isPopupOpen) } >
                    <AccountCircle style={{width: 24, height: 24, cursor: 'pointer'}} />
                </div>
                <div className={'DividerLine'} />
                {session.memberState !== 'regular'
                    ?<Button className={'LoginButton'} href={'/signin'} onClick={fn.gotoByAnchor} >
                        {'로그인'}
                    </Button>
                    :<AnchorButton href={`/enroll`} className={'AddNewWebtoonButton'} style={{backgroundColor: 'rgb(41,41,41)', borderRadius: '40px', color: 'rgb(255,255,255)', padding: '6px 16px'}}>
                        {'작품 등록'}
                    </AnchorButton>}
                {isLogout
                    ?<CheckLogout open={isLogout} setOpen={setIsLogout} />
                    :<></>}
            </div>
        </div>
        <div className={'MobileHeaderContainer'} style={isHiddenForMobile?{display:'none'}:{}}>
            <div className={'Mobile'} style={headerStyle}>
                <div className={'ArrowBox'} style={isBackable?{}:{width: 130}}>
                    {isBackable
                        ?<IconButton className={'IconBackground'} onClick={()=>fn.goto('/')}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        :<div className={'WebtoonTodayLogo'} onClick={() => {
                            setIsPopupOpen(false);
                            setIsPageMenuOpen(!isPageMenuOpen);
                        }} >
                            <MenuOutlined style={{padding: 0, color: 'rgb(228,229,231)'}} />
                        </div>
                    }
                </div>
                <div className={'PageBox'}>
                    {isBackable
                        ?isTitleHidden
                            ?''
                            :titleText
                        :''}
                </div>
                <div className={'ShortcutBox'}>
                    {mobileShortcuts?mobileShortcuts:<>
                        <IconButton
                            className={'SearchIcon'}
                            onClick={()=>fn.goto('/search')}
                        >
                            <SearchOutlined/>
                        </IconButton>
                        <IconButton
                            className={'LoginButton'} size={'medium'}
                            onClick={ () => {
                                setIsPageMenuOpen(false);
                                setIsPopupOpen(!isPopupOpen);
                            } }
                        >
                            <img style={{width:24, height:24, borderRadius: '50%'}} src={session.image && session.image !== 'https://static.webtoon.today/noimage.jpg'?session.image:'https://static.webtoon.today/noprofile.jpg'} alt={session.name || undefined}/>
                        </IconButton>
                </>}
                </div>
            </div>
            <PageMovingMenu isOpen={isPageMenuOpen} setIsOpen={setIsPageMenuOpen} />
            <PopupMenu isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} device={'mobile'} />
        </div>
    </div>
    )
}

export default Header;